import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import {
  Controller,
  FieldError,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import axios from "axios";
import fileDownload from "js-file-download";

import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import {
  ParcelRequestDocumentForm,
  ParcelRequestDocumentFormType,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  getDocumentDataCbm,
  getDocumentDataWeight,
  getParcelRequestDocumentTextFieldLabel,
} from "@sellernote/_shared/src/utils/forwarding/admin/trello";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";

import useSnackbar from "../../../../../../../hooks/useSnackbar";

import useParcelRequestDocumentPreview from "./useParcelRequestDocumentPreview";
import Styled from "./index.styles";

const ParcelRequestDocument = ({
  parcelRequestDocumentDrawerVisible,
  setParcelRequestDocumentDrawerVisible,
  bidId,
}: {
  parcelRequestDocumentDrawerVisible: boolean;
  setParcelRequestDocumentDrawerVisible: Dispatch<SetStateAction<boolean>>;
  bidId: number;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const { data: parcelDocumentDefaultData } =
    TRELLO_BID_QUERY.useGetParcelDocumentDefaultData(bidId);

  const {
    setValue,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<ParcelRequestDocumentForm>();

  const { mutate: updateDocument, isLoading } =
    TRELLO_BID_QUERY.useUpdateDocument(bidId);

  const { mutate: updateWarehouseInfo } =
    TRELLO_BID_QUERY.useUpdateWarehouseInfo(bidId);

  const { mutate: getFileDownloadUrl } =
    ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const parcelRequestDocumentHtml = useParcelRequestDocumentPreview(
    watch,
    parcelDocumentDefaultData?.docNum || ""
  );

  useEffect(() => {
    reset({
      prepaid: parcelDocumentDefaultData?.prepaid === false ? false : true,
      consignee:
        parcelDocumentDefaultData?.consignee ||
        parcelDocumentDefaultData?.company,
      blNumber: parcelDocumentDefaultData?.blNumber,
      arrivalCompany:
        parcelDocumentDefaultData?.arrivalCompany ||
        parcelDocumentDefaultData?.company,
      destinationAddress: parcelDocumentDefaultData?.destinationAddress,
      recipient: parcelDocumentDefaultData?.recipient,
      phone: parcelDocumentDefaultData?.phone,
      packageInfo: parcelDocumentDefaultData?.packageInfo,
      cbm: parcelDocumentDefaultData?.invoiceCbm
        ? getDocumentDataCbm(parcelDocumentDefaultData?.invoiceCbm)
        : parcelDocumentDefaultData?.cbm,
      weight: parcelDocumentDefaultData?.invoiceCbm
        ? getDocumentDataWeight(parcelDocumentDefaultData?.invoiceCbm)
        : parcelDocumentDefaultData?.weight,
      comment:
        "* 창고에 출고서류 전달했습니다. <br> * 본 건은 선불입니다. 창고료내역서 발송해주시기 바랍니다. ",
      adminName: parcelDocumentDefaultData?.adminName.includes("매니저")
        ? parcelDocumentDefaultData?.adminName
        : `${parcelDocumentDefaultData?.adminName} 매니저`,
      adminPhone: parcelDocumentDefaultData?.adminPhone,
      faxNumber: "0505 -901-7210",
      adminEmail: "cs.fw@ship-da.com",
      warehouseAddress: parcelDocumentDefaultData?.warehouseAddress || "",
      warehouseContact: parcelDocumentDefaultData?.warehouseContact || "",
      warehouseName: parcelDocumentDefaultData?.warehouseName || "",
    });
  }, [
    parcelDocumentDefaultData?.adminName,
    parcelDocumentDefaultData?.adminPhone,
    parcelDocumentDefaultData?.arrivalCompany,
    parcelDocumentDefaultData?.blNumber,
    parcelDocumentDefaultData?.cbm,
    parcelDocumentDefaultData?.company,
    parcelDocumentDefaultData?.consignee,
    parcelDocumentDefaultData?.destinationAddress,
    parcelDocumentDefaultData?.invoiceCbm,
    parcelDocumentDefaultData?.packageInfo,
    parcelDocumentDefaultData?.phone,
    parcelDocumentDefaultData?.prepaid,
    parcelDocumentDefaultData?.recipient,
    parcelDocumentDefaultData?.warehouseAddress,
    parcelDocumentDefaultData?.warehouseContact,
    parcelDocumentDefaultData?.warehouseName,
    parcelDocumentDefaultData?.weight,
    reset,
  ]);

  const handleDocumentDownload: SubmitHandler<ParcelRequestDocumentForm> =
    useCallback(
      (data) => {
        updateDocument(
          { fileData: { ...data, type: "parcelRequest" } },
          {
            onSuccess: ({ data: uploadSuccessData }) => {
              getFileDownloadUrl(
                {
                  pathParams: {
                    key: uploadSuccessData.pdfKey,
                  },
                },
                {
                  onSuccess: async ({ data: downloadSuccessData }) => {
                    const blobFile = await axios.get(downloadSuccessData.url, {
                      responseType: "blob",
                    });

                    fileDownload(blobFile.data, uploadSuccessData.fileName);
                    queryClient.invalidateQueries(
                      TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({ bidId })
                    );
                  },

                  onError: () => {
                    handleSnackbarOpen("다운로드에 실패했습니다.", "error");
                  },
                }
              );
            },

            onError: () => {
              handleSnackbarOpen("요청에 실패했습니다.", "error");
            },
          }
        );
      },
      [
        bidId,
        getFileDownloadUrl,
        handleSnackbarOpen,
        queryClient,
        updateDocument,
      ]
    );

  const handleDocumentUpload: SubmitHandler<ParcelRequestDocumentForm> =
    useCallback(
      (data) => {
        updateDocument(
          { fileData: { ...data, type: "parcelRequest" } },
          {
            onSuccess: () => {
              handleSnackbarOpen("업로드에 성공했습니다.");
              queryClient.invalidateQueries(
                TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({ bidId })
              );
            },

            onError: () => {
              handleSnackbarOpen("업로드에 실패했습니다.", "error");
            },
          }
        );
      },
      [bidId, handleSnackbarOpen, queryClient, updateDocument]
    );

  const handleWarehouseInfoUpdate = useCallback(() => {
    updateWarehouseInfo(
      {},
      {
        onSuccess: ({ data: successData }) => {
          if (successData?.warehouseName) {
            setValue("warehouseName", successData.warehouseName || "");
            setValue("warehouseAddress", successData.warehouseAddress || "");
            setValue("warehouseContact", successData.warehouseContact || "");
            handleSnackbarOpen("창고 정보가 업데이트되었습니다.", "success");
            return;
          }
          handleSnackbarOpen("창고명이 없습니다.", "error");
          setValue("warehouseName", "");
          setValue("warehouseAddress", "");
          setValue("warehouseContact", "");
        },

        onError: ({ response }) => {
          if (response?.data?.error === "bid not found") {
            handleSnackbarOpen("BL번호 등록에 문제가 있습니다.", "error");
          }

          if (response?.data?.errorCode === "E351") {
            handleSnackbarOpen("유니패스 데이터 결과가 없습니다.", "error");
          }

          if (response?.data?.errorCode === "E367") {
            handleSnackbarOpen(
              "보세창고 부호가 등록되지 않았거나 찾지 못했습니다.",
              "error"
            );
          }
          setValue("warehouseName", "");
          setValue("warehouseAddress", "");
          setValue("warehouseContact", "");
        },
      }
    );
  }, [handleSnackbarOpen, setValue, updateWarehouseInfo]);

  const handleDocumentDrawerClose = useCallback(() => {
    setParcelRequestDocumentDrawerVisible(false);
  }, [setParcelRequestDocumentDrawerVisible]);

  const handleTextFieldClear = useCallback(
    (textFieldType: ParcelRequestDocumentFormType) => {
      return () => {
        reset({ ...watch(), [`${textFieldType}`]: "" });
      };
    },
    [reset, watch]
  );

  const FormTextField = useCallback(
    ({
      textFieldError,
      textFieldType,
      multiline,
      rows,
      width,
    }: {
      textFieldError: FieldError | undefined;
      textFieldType: ParcelRequestDocumentFormType;
      multiline?: boolean;
      rows?: number;
      width?: number | string;
    }) => {
      return (
        <Controller
          control={control}
          name={textFieldType}
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => {
            return (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                multiline={multiline}
                rows={rows}
                inputRef={ref}
                sx={{ width: width }}
                handleTextFieldClear={handleTextFieldClear(textFieldType)}
                label={getParcelRequestDocumentTextFieldLabel(textFieldType)}
                error={textFieldError && textFieldError.type === "required"}
                helperText={
                  textFieldError &&
                  textFieldError.type === "required" &&
                  "필수 입력입니다."
                }
              />
            );
          }}
        />
      );
    },
    [control, handleTextFieldClear]
  );

  return (
    <Drawer
      anchor={"left"}
      open={parcelRequestDocumentDrawerVisible}
      onClose={handleDocumentDrawerClose}
    >
      <Styled.drawerBody>
        <Styled.header>
          <div>{parcelDocumentDefaultData?.docNum || "-"}</div>
        </Styled.header>
      </Styled.drawerBody>

      <Divider />

      <Styled.documentContainer>
        <Box
          component="form"
          autoComplete="off"
          className="create-container"
          onSubmit={handleSubmit(handleDocumentUpload)}
        >
          <div className="title">
            <Typography variant="h6" component="div">
              택배 오더 접수장
            </Typography>
          </div>

          <div className="input-container">
            <Controller
              control={control}
              name="prepaid"
              render={({ field: { ref, ...field } }) => {
                return (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        inputRef={ref}
                        value={field.value}
                        checked={field.value}
                      />
                    }
                    label="선불여부"
                  />
                );
              }}
            />

            <div className="text-field-wrapper">
              <FormTextField
                textFieldError={errors.blNumber}
                textFieldType={"blNumber"}
              />
            </div>

            <div className="text-field-wrapper">
              <FormTextField
                textFieldError={errors.consignee}
                textFieldType={"consignee"}
              />
            </div>

            <div className="text-field-container">
              <FormTextField
                textFieldError={errors.packageInfo}
                textFieldType={"packageInfo"}
              />

              <FormTextField
                textFieldError={errors.weight}
                textFieldType={"weight"}
              />

              <FormTextField
                textFieldError={errors.cbm}
                textFieldType={"cbm"}
              />
            </div>

            <div className="text-field-container-column">
              <FormTextField
                textFieldError={errors.warehouseName}
                textFieldType={"warehouseName"}
              />

              <FormTextField
                textFieldError={errors.warehouseAddress}
                textFieldType={"warehouseAddress"}
              />

              <FormTextField
                textFieldError={errors.warehouseContact}
                textFieldType={"warehouseContact"}
              />
            </div>

            <div className="text-field-container-column">
              <FormTextField
                textFieldError={errors.arrivalCompany}
                textFieldType={"arrivalCompany"}
              />

              <FormTextField
                textFieldError={errors.destinationAddress}
                textFieldType={"destinationAddress"}
              />

              <div>
                <FormTextField
                  textFieldError={errors.recipient}
                  textFieldType={"recipient"}
                />

                <FormTextField
                  textFieldError={errors.phone}
                  textFieldType={"phone"}
                />
              </div>
            </div>

            <div className="text-field-wrapper">
              <FormTextField
                textFieldError={errors.comment}
                textFieldType={"comment"}
                width={"100%"}
                multiline={true}
                rows={3}
              />
            </div>

            <div className="partner-name">
              <FormTextField
                textFieldError={errors.adminName}
                textFieldType={"adminName"}
              />

              <FormTextField
                textFieldError={errors.adminPhone}
                textFieldType={"adminPhone"}
              />

              <FormTextField
                textFieldError={errors.faxNumber}
                textFieldType={"faxNumber"}
              />

              <FormTextField
                textFieldError={errors.adminEmail}
                textFieldType={"adminEmail"}
              />
            </div>
          </div>

          <Styled.uploadButton>
            <Button color="success" variant="contained" type="submit">
              {isLoading ? (
                <CircularProgress color="secondary" size={24} />
              ) : (
                "업로드"
              )}
            </Button>
          </Styled.uploadButton>

          <Styled.downloadButton>
            <Button
              variant="contained"
              onClick={handleSubmit(handleDocumentDownload)}
            >
              {isLoading ? (
                <CircularProgress color="secondary" size={24} />
              ) : (
                "다운로드"
              )}
            </Button>
          </Styled.downloadButton>

          <Styled.updateWarehouseInfoButton>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleWarehouseInfoUpdate}
            >
              창고명
            </Button>
          </Styled.updateWarehouseInfoButton>
        </Box>

        <Styled.preview
          dangerouslySetInnerHTML={{
            __html: parcelRequestDocumentHtml,
          }}
        />
      </Styled.documentContainer>
    </Drawer>
  );
};

export default ParcelRequestDocument;
