import { ZoomIn, ZoomOut } from "@mui/icons-material";
import { Box, Button, Divider, Tab, Tabs } from "@mui/material";

import { PreviewZoomProps } from "../types";

const COMMON_STYLE = { borderBottom: 1, borderColor: "divider" };

const PDF_FORMAT_LIST = ["pdf", "PDF", "tiff", "tif", "TIF"];

export default function PreviewTypeTab({
  tab,
  onTabChange,
  isImport,
  fileExtension,
  onPdfScaleChange,
  onImageWidthChange,
}: {
  tab: "arrivalNotice" | "BL" | "exportDeclaration";
  onTabChange: (v: "arrivalNotice" | "BL" | "exportDeclaration") => void;
  isImport: boolean;
  fileExtension: string | undefined;
  onPdfScaleChange: (value: PreviewZoomProps) => void;
  onImageWidthChange: (value: PreviewZoomProps) => void;
}) {
  const isPdf = PDF_FORMAT_LIST.includes(fileExtension ?? "");

  return (
    <Tabs value={tab} onChange={(_, v) => onTabChange(v)}>
      {isImport ? (
        <Tab label="AN(적하목록)" value="arrivalNotice" sx={COMMON_STYLE} />
      ) : (
        <Tab label="수출신고필증" value="exportDeclaration" sx={COMMON_STYLE} />
      )}

      <Tab label="B/L" value="BL" sx={COMMON_STYLE} />

      <Divider orientation="vertical" flexItem sx={{ ml: 3, mr: 3 }} />

      {/** 현재는 PDF, 이미지 포맷만 확대/축소 기능을 지원 */}
      <Box
        display={"flex"}
        gap={1}
        alignSelf={"flex-end"}
        justifySelf={"flex-end"}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (isPdf) {
              onPdfScaleChange("zoomOut");
            } else {
              onImageWidthChange("zoomOut");
            }
          }}
        >
          <ZoomOut />
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (isPdf) {
              onPdfScaleChange("zoomIn");
            } else {
              onImageWidthChange("zoomIn");
            }
          }}
        >
          <ZoomIn />
        </Button>
      </Box>
    </Tabs>
  );
}
