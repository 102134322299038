import { Box } from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

import useHandlePreviewContent from "./hooks/useHandlePreviewContent";

import PreviewContent from "./PreviewContent";
import PreviewFooter from "./PreviewFooter";
import PreviewTypeTab from "./PreviewTypeTab";

export default function ArrivalNoticePreview({
  shipmentId,
  isImport,
}: {
  shipmentId: number;
  isImport: boolean;
}) {
  const { data } = TRELLO_BID_QUERY.useGetTrelloAttachments({
    bidId: shipmentId,
  });

  const {
    imageWidth,
    pdfScale,
    tab,
    previewIndex,
    handleImageWidthChange,
    handlePdfScaleChange,
    handlePreviewIndexChange,
    handleTabChange,
  } = useHandlePreviewContent({
    isImport,
  });

  const previewContentList = data?.filter((v) => v.domain === tab);

  const previewContent = previewContentList?.[previewIndex];

  const fileExtension = previewContent?.name.substring(
    previewContent.name.lastIndexOf(".") + 1
  );

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <PreviewTypeTab
        tab={tab}
        onTabChange={handleTabChange}
        isImport={isImport}
        fileExtension={fileExtension}
        onPdfScaleChange={handlePdfScaleChange}
        onImageWidthChange={handleImageWidthChange}
      />

      <PreviewContent
        previewContent={previewContent}
        fileExtension={fileExtension}
        pdfScale={pdfScale}
        imageWidth={imageWidth}
      />

      <PreviewFooter
        onPreviewIndexChange={handlePreviewIndexChange}
        isPreviousButtonDisabled={previewIndex === 0}
        /** 더 이상 파일이 없는 경우  */
        isNextButtonDisabled={
          !previewContentList?.length ||
          previewIndex + 1 === previewContentList?.length
        }
      />
    </Box>
  );
}
