import { useEffect, useState } from "react";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

const useGetCargoManifestInfo = ({
  shipmentId,
  hasCargoManifest,
}: {
  shipmentId: number;
  hasCargoManifest: boolean;
}) => {
  const [cargoManifestInfo, setCargoManifestInfo] =
    useState<CargoManifestInfoProps | null>(null);

  const [showsLoading, setShowsLoading] = useState(true);

  const {
    mutate: createNewCargoManifestInfo,
    ResponseHandler: ResponseHandlerOfCreateNewCargoManifestInfo,
  } = TRELLO_BID_QUERY.useCreateCargoManifest({ shipmentId });

  TRELLO_BID_QUERY.useGetCargoManifest({
    shipmentId,
    onSuccess: (data) => setCargoManifestInfo(data),
    enabled: hasCargoManifest,
  });

  /** 마운트 됐을 때, 적하목록 제출 존재 API 응답값 여부에 따라 생성 API 요청 & 상태에 바인딩 */
  useEffect(() => {
    if (!hasCargoManifest) {
      createNewCargoManifestInfo(
        {},
        {
          onSuccess: (data) => setCargoManifestInfo(data.data),
        }
      );
    }
  }, [createNewCargoManifestInfo, hasCargoManifest]);

  /** 스켈레톤을 보여주기 위한 훅 */
  useEffect(() => {
    const timer = setTimeout(() => setShowsLoading(false), 2000);

    return () => clearTimeout(timer);
  }, []);

  return {
    cargoManifestInfo,
    showsLoading,
    ResponseHandlerOfCreateNewCargoManifestInfo,
  };
};

export default useGetCargoManifestInfo;
