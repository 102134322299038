import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";

const COMMON_KEYS: (keyof CargoManifestInfoProps)[] = [
  "carrierCode",
  "mBL",
  "hBL",
  "MRN",
  "callSign",
  "packageCount",
  "packageSign",
  "totalWeight",
  "totalCBM",
  "itemName",
  "shipper",
  "shipperAddress",
  "consignee",
  "consigneeAddress",
  "notifyParty",
  "notifyPartyAddress",
  "classification",
  "notifyPartyBRN",
  "consigneeBRN",
];

const IMPORT_KEYS: (keyof CargoManifestInfoProps["importation"])[] = [
  "ETA",
  "MSN",
  "terminalCode",
  "spclCode",
  "assignCode",
];

const EXPORT_KEYS: (keyof CargoManifestInfoProps["exportation"])[] = [
  "ETD",
  "partialShipment",
  "declarationNumber",
];

export { COMMON_KEYS, IMPORT_KEYS, EXPORT_KEYS };
