import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useAtomValue } from "jotai";

import { UPDATE_BL_NUMBER_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import {
  FreightType,
  Liner,
} from "@sellernote/_shared/src/types/common/common";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import {
  BLType,
  TrelloBidManagement,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { BL_TYPE_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";

import useMuiSelect from "../../../../../../../hooks/useMuiSelect";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../../../jotaiStates/auth";
import {
  BL_TRACKING_ERROR_PREFIX,
  FCL_BL_TRACKING_POLICY_MAP,
  LCL_AND_AIR_BL_TRACKING_POLICY_MAP,
} from "../../BL_TRACKING_POLICY_MAP";
import useHandleOpCheckPoint from "./useHandleOpCheckPoint";

function useUpdateBLnumberAndLinerLogic({
  management,
  projectStatus,
  freightType,
  linerData,
  isExportation,
}: {
  management: TrelloBidManagement;
  projectStatus: BidProjectStatus;
  freightType: FreightType;
  linerData: Liner[];
  isExportation: boolean;
}) {
  const queryClient = useQueryClient();

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const authority = currentAdminAuthInfo?.authority;

  const [isEditMode, setIsEditMode] = useState(false);
  const [linerId, setLinerId] = useState<number | undefined | null>(
    management.linerId
  );
  const [HBL, setHBL] = useState(management.hBL);
  const [MBL, setMBL] = useState(management.mBL);

  const { handleCheckPointUpdate, ResponseHandlerOfUpdateOpCheckPoint } =
    useHandleOpCheckPoint({
      shipmentId: management.bidId,
    });

  const {
    mutate: updateBLNumber,
    ResponseHandler: ResponseHandlerOfUpdateBlNumber,
  } = TRELLO_BID_QUERY.useUpdateBLNumber({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setIsEditMode(false);
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
      },
      customizeMessage: (res: UPDATE_BL_NUMBER_RES | undefined) => {
        if (!res) return {};

        if (freightType !== "FCL") {
          return {
            messageType: "titleAndBody",
            title: "등록이 완료되었습니다.",
            body: LCL_AND_AIR_BL_TRACKING_POLICY_MAP[freightType],
          };
        }

        return {
          messageType: "titleAndBody",
          title: "등록이 완료되었습니다.",
          body: (
            <>
              {FCL_BL_TRACKING_POLICY_MAP[res.message] ? (
                FCL_BL_TRACKING_POLICY_MAP[res.message]
              ) : (
                <>
                  {BL_TRACKING_ERROR_PREFIX}
                  <br />
                  예측하지 못한 오류가 발생했습니다. 지원_포워딩 채널로
                  문의해주세요.
                </>
              )}
            </>
          ),
        };
      },
    },
    bidId: management.bidId,
  });

  const shipNameList = useMemo(() => {
    return linerData
      .filter((v) => {
        if (freightType === "AIR") {
          return v.type === "air";
        }
        return v.type !== "air";
      })
      .map((v: Liner) => {
        return {
          value: v.id,
          label: v.name,
        };
      });
  }, [freightType, linerData]);

  const selectOptionListOfBLType = useMemo(() => {
    return BL_TYPE_OPTION_LIST.filter((option) => {
      if (freightType === "FCL") {
        return (
          option.label !== "HBL" &&
          option.label !== "MBL" &&
          option.label !== "MAWB/HAWB" &&
          option.label !== "MAWB Direct"
        );
      }

      if (freightType === "LCL") {
        return (
          option.label !== "MBL Direct" &&
          option.label !== "MBL" &&
          option.label !== "MAWB/HAWB" &&
          option.label !== "MAWB Direct"
        );
      }

      return (
        option.label !== "MBL Direct" &&
        option.label !== "MBL" &&
        option.label !== "HBL" &&
        option.label !== "MBL/HBL"
      );
    });
  }, [freightType]);

  const getLinerName = useCallback(
    (linerId: number) => {
      const liner = linerData?.find((linerItem) => {
        return linerItem.id === linerId;
      });

      if (liner) {
        return liner.name;
      }
      return "";
    },
    [linerData]
  );

  const handleMBLInputBlur = useCallback(() => {
    const inputValueOfMBL = MBL;

    if (!inputValueOfMBL) {
      return;
    }

    const linerWithMBLPrefixId = linerData.find((n) => {
      return inputValueOfMBL.startsWith(n.MBLprefix);
    })?.id;

    if (linerWithMBLPrefixId) {
      setLinerId(linerWithMBLPrefixId);
    }

    return;
  }, [MBL, linerData]);

  const checkIfRequestButtonCanBeRendered = () => {
    if (management.hBL || management.mBL) {
      return isEditMode;
    }
    return true;
  };

  /** BL 등록 인풋들에 공통으로 적용되는 disabled 여부 */
  const isCommonInputDisabled = (() => {
    /** BL이 하나라도 입력됐다면 등록이 됐기 때문에 수정여부로 활성/비활성됨 */
    if (management.hBL || management.mBL) {
      if (isEditMode) {
        return false;
      }
      return true;
    }
    return false;
  })();

  const getValueOfHBlRequest = () => {
    if (selectedValueOfBLType === "MBL") {
      return null;
    } else {
      if (!HBL) {
        return null;
      } else {
        return HBL.trim();
      }
    }
  };

  const getValueOfMBlRequest = () => {
    if (selectedValueOfBLType === "HBL") {
      return null;
    } else {
      if (!MBL) {
        return null;
      } else {
        return MBL.trim();
      }
    }
  };

  const { selectedValue: selectedValueOfBLType, MuiSelect: BLTypeSelect } =
    useMuiSelect({
      options: selectOptionListOfBLType,
      title: "BL 타입",
      minWidth: 120,
      defaultValue: management.BLType,
      disabled: isCommonInputDisabled,
    });

  const handleClickUpdateButton = () => {
    return updateBLNumber({
      hBL: getValueOfHBlRequest(),
      mBL: getValueOfMBlRequest(),
      BLType: selectedValueOfBLType as BLType,
      linerId,
    });
  };

  const getRequestButtonDisabled = () => {
    if (selectedValueOfBLType === "ALL") {
      if (freightType === "FCL" || freightType === "LCL") {
        if (!HBL || !linerId || !MBL) {
          return true;
        }
        return false;
      }

      if (!HBL || !MBL) {
        return true;
      }
      return false;
    }

    if (selectedValueOfBLType === "DirectMBL") {
      if (!MBL || !linerId) {
        return true;
      }
      return false;
    }

    if (selectedValueOfBLType === "MBL") {
      if (!MBL) {
        return true;
      }
      return false;
    }

    if (selectedValueOfBLType === "HBL") {
      if (!HBL) {
        return true;
      }
      return false;
    }

    return true;
  };

  /** BL 유형을 변경할 수 있는 운송 상태 */
  const isPossibleChangeBLType =
    projectStatus !== "beforeContactPartner" &&
    projectStatus !== "contactingPartner";

  const showsLinerSelect =
    shipNameList && (selectedValueOfBLType !== "HBL" || freightType === "AIR");

  /** BL 유형 셀렉트 버튼 disabled 여부 */
  const isBLTypeSelectDisabled = (() => {
    /** 수출 AIR인 경우에는 MBL/HBL 입력과 상관없이 isEditMode 여부에 따라서만 활성화 */
    if (isExportation && freightType === "AIR") {
      return !isEditMode;
    }

    return isCommonInputDisabled || !isPossibleChangeBLType;
  })();

  return {
    BLTypeSelect,
    handleCheckPointUpdate,
    isCommonInputDisabled,
    selectedValueOfBLType,
    MBL,
    HBL,
    handleMBLInputBlur,
    setMBL,
    setHBL,
    shipNameList,
    showsLinerSelect,
    setLinerId,
    isEditMode,
    authority,
    setIsEditMode,
    checkIfRequestButtonCanBeRendered,
    handleClickUpdateButton,
    ResponseHandlerOfUpdateBlNumber,
    ResponseHandlerOfUpdateOpCheckPoint,
    linerId,
    getLinerName,
    getRequestButtonDisabled,
    isBLTypeSelectDisabled,
  };
}

export default useUpdateBLnumberAndLinerLogic;
