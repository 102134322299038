import { useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

export default function ClearCautionForLFD({
  shipmentId,
  isClearedCautionForLFD,
}: {
  shipmentId: number;
  /** 이미 프리타임 주의 해제했는지 여부 */
  isClearedCautionForLFD: boolean;
}) {
  const queryClient = useQueryClient();

  const [opens, setOpens] = useState(false);

  const handleClose = () => setOpens(false);

  const { mutate: clearCautionForLFD, ResponseHandler } =
    TRELLO_BID_QUERY.useClearCautionForLFD({
      onSuccess: () => {
        handleClose();

        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getTrelloFreeTime({ shipmentId })
        );
      },
    });

  return (
    <>
      <Button
        disabled={isClearedCautionForLFD}
        onClick={() => setOpens(true)}
        variant="contained"
        size="small"
        color="success"
      >
        <Typography variant="body2" sx={{ width: "130px" }}>
          프리타임 주의 해제
        </Typography>
      </Button>

      <Dialog
        open={opens}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          프리타임 주의 표시를 즉시 해제시킬까요?
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={() => clearCautionForLFD({ shipmentId })}
            autoFocus
            variant="contained"
          >
            예
          </Button>

          <Button onClick={handleClose} variant="outlined">
            취소
          </Button>
        </DialogActions>
      </Dialog>

      {ResponseHandler}
    </>
  );
}
