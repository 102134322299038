import { ChangeEvent, useCallback, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { GET_ADMIN_SETTLEMENT_LIST_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { SETTLEMENT_PROJECT_STATUS_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";

function SettlementTableFilter({
  adminUserList,
  fetchSettlementListParams,
  setFetchSettlementListParams,
}: {
  adminUserList: ForwardingAdminUserListItem[] | undefined;
  fetchSettlementListParams: Omit<
    GET_ADMIN_SETTLEMENT_LIST_REQ,
    "isSettlement" | "status" | "serviceType"
  >;
  setFetchSettlementListParams: (
    value: Omit<
      GET_ADMIN_SETTLEMENT_LIST_REQ,
      "isSettlement" | "status" | "serviceType"
    >
  ) => void;
}) {
  const [searchType, setSearchType] = useState("company");
  const [searchTerm, setSearchTerm] = useState("");

  const forwardingManagerId = useMemo(() => {
    if (!fetchSettlementListParams.adminId) {
      return "all";
    }
    return fetchSettlementListParams.adminId;
  }, [fetchSettlementListParams.adminId]);

  const handleSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchTerm(event.target.value);
    },
    []
  );

  const handleForwardingManagerIdChange = useCallback(
    (event: SelectChangeEvent<number | "all">) => {
      setFetchSettlementListParams({
        ...fetchSettlementListParams,
        adminId:
          event.target.value === "all"
            ? undefined
            : (event.target.value as number),
      });
    },
    [fetchSettlementListParams, setFetchSettlementListParams]
  );

  const handleSearchTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      setSearchType(event.target.value);
      setSearchTerm("");
      setFetchSettlementListParams({
        ...fetchSettlementListParams,
        bidId: undefined,
        company: undefined,
        BL: undefined,
      });
    },
    [fetchSettlementListParams, setFetchSettlementListParams]
  );

  const handleSearchInputClear = useCallback(() => {
    setSearchTerm("");

    if (searchType === "BL") {
      setFetchSettlementListParams({
        ...fetchSettlementListParams,
        BL: undefined,
      });
      return;
    }
    if (searchType === "bidId") {
      setFetchSettlementListParams({
        ...fetchSettlementListParams,
        bidId: undefined,
      });
      return;
    }
    setFetchSettlementListParams({
      ...fetchSettlementListParams,
      company: undefined,
    });
  }, [fetchSettlementListParams, searchType, setFetchSettlementListParams]);

  const handleSearchButtonClick = useCallback(() => {
    if (searchType === "BL") {
      setFetchSettlementListParams({
        ...fetchSettlementListParams,
        BL: searchTerm,
      });
      return;
    }
    if (searchType === "bidId") {
      setFetchSettlementListParams({
        ...fetchSettlementListParams,
        bidId: Number(searchTerm),
      });
      return;
    }
    setFetchSettlementListParams({
      ...fetchSettlementListParams,
      company: searchTerm,
    });
  }, [
    fetchSettlementListParams,
    searchTerm,
    searchType,
    setFetchSettlementListParams,
  ]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
        handleSearchButtonClick();
      }
    },
    [handleSearchButtonClick]
  );

  const handleProjectStatusChange = useCallback(
    (event: SelectChangeEvent<BidProjectStatus>) => {
      setFetchSettlementListParams({
        ...fetchSettlementListParams,
        projectStatus: event.target.value as BidProjectStatus,
      });
    },
    [fetchSettlementListParams, setFetchSettlementListParams]
  );

  return (
    <Box sx={{ display: "flex", gap: 1, p: 1, background: "#fff" }}>
      <Box sx={{ display: "flex", gap: "4px" }}>
        <FormControl sx={{ width: 150 }} size="small">
          <InputLabel>담당자</InputLabel>

          <Select
            value={forwardingManagerId}
            onChange={handleForwardingManagerIdChange}
          >
            <MenuItem value={"all"}>전체</MenuItem>
            {adminUserList
              ?.filter((v: ForwardingAdminUserListItem) => {
                return v.isForwardingManager;
              })
              .map((v: ForwardingAdminUserListItem) => {
                return (
                  <MenuItem key={v.id} value={v.id}>
                    {v.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        <FormControl sx={{ width: 150 }} size="small">
          <InputLabel>비고</InputLabel>

          <Select
            value={fetchSettlementListParams.projectStatus}
            onChange={handleProjectStatusChange}
          >
            {SETTLEMENT_PROJECT_STATUS_OPTION_LIST.map((v) => {
              return (
                <MenuItem value={v.value} key={v.value}>
                  {v.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: "flex", gap: "4px" }}>
        <FormControl sx={{ width: 120 }} size="small">
          <InputLabel>검색타입</InputLabel>

          <Select
            value={searchType}
            label="검색타입"
            onChange={handleSearchTypeChange}
          >
            <MenuItem value="company">{"회사명"}</MenuItem>
            <MenuItem value="bidId">{"의뢰번호"}</MenuItem>
            <MenuItem value="BL">{"BL"}</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label={"검색어"}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearchInputClear}>
                  <CloseIcon sx={{ color: "red", fontSize: 20 }} />
                </IconButton>

                <IconButton onClick={handleSearchButtonClick}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={searchTerm}
          onChange={handleSearchInputChange}
          sx={{ width: 300 }}
          size="small"
        />
      </Box>
    </Box>
  );
}

export default SettlementTableFilter;
