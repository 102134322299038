import { Box, Table, TableContainer, TablePagination } from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

import usePagination from "./hooks/usePagination";

import HistoryTableBody from "./HistoryTableBody";
import HistoryTableHead from "./HistoryTableHead";
import Title from "./Title";

export default function ShipmentHistoryTable({
  shipmentId,
}: {
  shipmentId: number;
}) {
  const { page, rowsPerPage, handlePageChange, handlePaginationOptionReset } =
    usePagination();

  const { data: scheduleLog } = TRELLO_BID_QUERY.useGetScheduleLog({
    page,
    perPage: rowsPerPage,
    shipmentId: shipmentId,
  });

  return (
    <Box>
      <Title />

      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader>
          <HistoryTableHead />

          <HistoryTableBody rowList={scheduleLog?.list ?? []} />
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={scheduleLog?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, page) => handlePageChange(page)}
        onRowsPerPageChange={handlePaginationOptionReset}
      />
    </Box>
  );
}
