import { useMemo } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Checkbox, Grid, Tooltip, Typography } from "@mui/material";

import { GET_FINANCIAL_DEPOSIT_HISTORY_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";
import { isEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import { usePrevious } from "@sellernote/_shared/src/utils/common/hook";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { changeInvoiceTypeToKr } from "@sellernote/_shared/src/utils/forwarding/admin/trello";

import Table, {
  TableBodyRow,
  TableHeadCell,
} from "../../../../../../../components/Table";

import { DepositHistoryTableCellId } from "..";

function DepositHistoryTable({
  checkBoxSet,
  toggleCheckBox,
  toggleTotalDepositAmount,
  depositHistoryData,
  TermSearchPanel,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  debouncedSearchTerm,
}: {
  checkBoxSet: Set<number>;
  toggleCheckBox: (value: number) => void;
  toggleTotalDepositAmount: (value: Record<number, number>) => void;
  depositHistoryData: GET_FINANCIAL_DEPOSIT_HISTORY_RES | undefined;
  TermSearchPanel: JSX.Element;
  perPage: number;
  setPerPage: (value: number) => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  debouncedSearchTerm: unknown;
}) {
  const previousDebouncedSearTerm = usePrevious(debouncedSearchTerm);

  if (debouncedSearchTerm !== previousDebouncedSearTerm) {
    setCurrentPage(0);
  }

  const headCells: TableHeadCell<DepositHistoryTableCellId>[] = useMemo(() => {
    return [
      {
        id: "checkbox",
        disablePadding: false,
        label: "선택",
      },
      {
        id: "transactionDateTime",
        disablePadding: false,
        label: "입금일시",
        width: 120,
      },
      {
        id: "bankName",
        disablePadding: false,
        label: "수취은행",
      },
      {
        id: "depositName",
        disablePadding: false,
        label: "입금자명",
        width: 150,
      },
      {
        id: "currency",
        disablePadding: false,
        label: "통화",
      },
      {
        id: "depositAmount",
        disablePadding: false,
        label: "입금액",
      },
      {
        id: "remark",
        disablePadding: false,
        label: (
          <Tooltip title="입금액이 다른 거래명세서에 매칭된 경우 내용을 알 수 있습니다.">
            <Grid container alignItems={"baseline"}>
              <Typography variant="body2">비고</Typography>

              <InfoOutlinedIcon sx={{ fontSize: "12px", cursor: "pointer" }} />
            </Grid>
          </Tooltip>
        ),
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!depositHistoryData?.list) return [];

    return depositHistoryData.list.map((v) => {
      const row: TableBodyRow<DepositHistoryTableCellId> = {
        checkbox: (
          <Checkbox
            checked={checkBoxSet.has(v.id)}
            onClick={(e) => {
              toggleCheckBox(v.id);
              toggleTotalDepositAmount({ [v.id]: v.depositAmount });
            }}
          />
        ),
        transactionDateTime: v.transactionDateTime,
        bankName: v.bankName,
        depositName: v.depositName,
        currency: v.currency,
        depositAmount: toThousandUnitFormat(v.depositAmount),
        remark: v.remarkFinalPriceTotal !== 0 && (
          <Grid container direction="column" textAlign="right">
            <Grid item>{toThousandUnitFormat(v.remarkFinalPriceTotal)}</Grid>

            {v.remark?.map((n) => {
              return (
                <Grid item key={n.invoiceId}>
                  <Typography variant="body2">{`(${
                    n.bidId
                  } ${changeInvoiceTypeToKr(n.invoiceType)})`}</Typography>
                </Grid>
              );
            })}
          </Grid>
        ),
      };

      return row;
    });
  }, [
    checkBoxSet,
    depositHistoryData?.list,
    toggleCheckBox,
    toggleTotalDepositAmount,
  ]);

  return (
    <Table
      toolbarItems={{
        left: [TermSearchPanel],
      }}
      headCells={headCells}
      rows={rows}
      pagination={{
        rowsPerPageOptions: [5, 10],
        totalCount: depositHistoryData?.total || 0,
        perPage,
        setPerPage,
        currentPage,
        setCurrentPage,
      }}
      showsNoDataFound={isEmptyObjectOrArray(rows)}
    />
  );
}

export default DepositHistoryTable;
