import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useAtomValue } from "jotai";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

import useSnackbar from "../../../../../../../../../hooks/useSnackbar";
import { getScheduleListForPayload } from "../../utils";

import { FORWARDING_ADMIN_TRELLO_ATOMS } from "../../../../../../../../../jotaiStates/trello";

export default function useHandleUpdateSchedule({
  shipmentId,
  scheduleChangeReason,
  setScheduleChangeReason,
  isScheduleModifiedBySeaRatesOrLiner,
}: {
  shipmentId: number;
  scheduleChangeReason: string;
  setScheduleChangeReason: (val: string) => void;
  isScheduleModifiedBySeaRatesOrLiner?: boolean;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [showsScheduleChangeReasonModal, setShowsScheduleChangeReasonModal] =
    useState(false);

  const queryClient = useQueryClient();

  const { mutate: updateShipmentSchedule } =
    TRELLO_BID_QUERY.useUpdateShipmentSchedule();

  const shipmentScheduleList = useAtomValue(
    FORWARDING_ADMIN_TRELLO_ATOMS.SHIPMENT_SCHEDULE_LIST
  );

  /** 자동 갱신 중단 여부 */
  const [stopsAutoRenewal, setStopsAutoRenewal] = useState(true);
  /** 저장 + 이메일 발송 여부 */
  const [sendsEmail, setSendsEmail] = useState(false);

  /** 스케줄 변경 */
  const handleScheduleUpdate = useCallback(() => {
    if (!scheduleChangeReason) {
      handleSnackbarOpen("스케줄 변경 사유를 입력해주세요.", "warning");
      return;
    }

    /** 스케줄 최초입력, 변경에 필요한 요청 공통 payload */
    const payload = {
      routes: getScheduleListForPayload(shipmentScheduleList),
      sendEmail: sendsEmail,
      pathParams: { shipmentId },
      scheduleChangeReason,
      /** SeaRates 또는 선사에 의해 스케줄이 수정된 경우, 자동 갱신 중단 여부를 Payload에 추가 */
      ...(isScheduleModifiedBySeaRatesOrLiner
        ? { inActivate: stopsAutoRenewal }
        : []),
    };

    /** 스케줄 변경 성공시 데이터 갱신, 모달 닫음 */
    const handleSuccess = () => {
      setShowsScheduleChangeReasonModal(false);
      setScheduleChangeReason("");
      setSendsEmail(false);

      /** 성공시 스낵바 노출 */
      handleSnackbarOpen("스케줄 변경에 성공했습니다.", "success");

      return Promise.all([
        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getShipmentScheduleDetail({ shipmentId })
        ),
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail()),
      ]);
    };

    /** 스케줄 변경의 경우, 스케줄 변경 사유와 자동 갱신 여부를 payload에 추가 */
    updateShipmentSchedule(payload, { onSuccess: handleSuccess });
  }, [
    scheduleChangeReason,
    shipmentScheduleList,
    sendsEmail,
    shipmentId,
    isScheduleModifiedBySeaRatesOrLiner,
    stopsAutoRenewal,
    updateShipmentSchedule,
    handleSnackbarOpen,
    setScheduleChangeReason,
    queryClient,
  ]);

  return {
    showsScheduleChangeReasonModal,
    setShowsScheduleChangeReasonModal,

    stopsAutoRenewal,
    setStopsAutoRenewal,

    sendsEmail,
    setSendsEmail,

    handleScheduleUpdate,
  };
}
