import { useState } from "react";
import { useQueryClient } from "react-query";

import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";

import useSnackbar from "../../../../../hooks/useSnackbar";

export default function useCompanyNameUpdateModal({
  userId,
  shipmentTeamId,
  shipmentId,
}: {
  userId: number;
  shipmentTeamId: number;
  shipmentId: number;
}) {
  const queryClient = useQueryClient();

  const { handleSnackbarOpen } = useSnackbar();

  const { data: userCompanyNameList } =
    ADMIN_BID_QUERY.useGetShipmentUserCompanyNameList({
      userId,
    });

  const { mutate: updateShipmentUserCompanyName } =
    ADMIN_BID_QUERY.useShipmentUserCompanyNameUpdate();

  const [opensCompanyNameUpdateModal, setOpensCompanyNameUpdateModal] =
    useState(false);
  const [selectShipmentTeamId, setSelectShipmentTeamId] =
    useState(shipmentTeamId);

  const handleCompanyNameUpdateModalOpen = () => {
    setOpensCompanyNameUpdateModal(true);
  };

  const handleCompanyNameUpdateModalClose = () => {
    setOpensCompanyNameUpdateModal(false);
  };

  const handleCompanyNameSelectChange = (value: string | number) => {
    const parsedNumberValue = Number(value);

    /** MUI Select의 type이 string도 리턴하고 있어 number로 변환 이때 예외 사항을 확인해서 에러 호출 */
    if (isNaN(parsedNumberValue)) {
      handleSnackbarOpen(`잘못된 회사명을 선택했습니다.`, "error");
      return;
    }

    setSelectShipmentTeamId(parsedNumberValue);
  };

  const handleCompanyNameUpdateButtonClick = () => {
    updateShipmentUserCompanyName(
      {
        teamId: selectShipmentTeamId,
        pathParams: {
          shipmentId,
        },
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("회사명을 변경했습니다.");
          setOpensCompanyNameUpdateModal(false);

          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: shipmentId,
            })
          );
        },

        onError: ({ response }) => {
          handleSnackbarOpen(
            `요청에 실패했습니다. error: ${response?.data.error}`,
            "error"
          );
        },
      }
    );
  };

  return {
    opensCompanyNameUpdateModal,
    handleCompanyNameUpdateModalOpen,
    handleCompanyNameUpdateModalClose,
    userCompanyNameList,
    handleCompanyNameUpdateButtonClick,
    handleCompanyNameSelectChange,
    selectShipmentTeamId,
  };
}
