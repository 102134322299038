import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import useSnackbar from "../../../../../../hooks/useSnackbar";

import { PartnerListForAutoComplete } from "../usePartnerNameAutoCompleteOptions";

function RegisterSettlementPartnerModal({
  opensRegisterSettlementPartnerModal,
  setOpensRegisterSettlementPartnerModal,
  bidAccountPayableId,
  etcPartnerListForAutoComplete,
  processSuccessResponse,
}: {
  opensRegisterSettlementPartnerModal: boolean;
  setOpensRegisterSettlementPartnerModal: Dispatch<SetStateAction<boolean>>;
  bidAccountPayableId: number;
  etcPartnerListForAutoComplete: PartnerListForAutoComplete[];
  processSuccessResponse: (message: string) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [partnerId, setPartnerId] = useState(0);

  const { mutate: changeForeignWithdrawalTarget } =
    TRELLO_BID_QUERY.useChangeForeignWithdrawalTarget();

  const handleSaveButtonClick = useCallback(() => {
    changeForeignWithdrawalTarget(
      {
        foreignWithdrawalTargetCompanyId: partnerId,
        isForeignWithdrawalTarget: false,
        pathParams: { bidAccountPayableId },
      },
      {
        onSuccess: () => {
          setOpensRegisterSettlementPartnerModal(false);
          processSuccessResponse("정산 파트너를 등록했습니다. ");
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [
    changeForeignWithdrawalTarget,
    partnerId,
    bidAccountPayableId,
    setOpensRegisterSettlementPartnerModal,
    processSuccessResponse,
    handleSnackbarOpen,
  ]);

  const handleAutoCompleteChange = useCallback(
    (newValue: PartnerListForAutoComplete | null) => {
      if (!newValue || newValue.value === "shipper" || !newValue.value) {
        return setPartnerId(0);
      }

      return setPartnerId(newValue.value);
    },
    []
  );

  const handleModalClose = useCallback(() => {
    setOpensRegisterSettlementPartnerModal(false);
  }, [setOpensRegisterSettlementPartnerModal]);

  const ModalBody = useMemo(() => {
    return (
      <>
        <Typography variant="h6" component="div">
          정산할 회사를 입력하세요
        </Typography>

        <Grid
          container
          direction="column"
          spacing={2}
          sx={{ marginTop: 1, maxWidth: 400 }}
        >
          <Grid item xs={6}>
            <Autocomplete
              onChange={(event, newValue) => {
                handleAutoCompleteChange(newValue);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disablePortal
              options={etcPartnerListForAutoComplete}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              color="success"
              onClick={handleSaveButtonClick}
            >
              저장
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }, [
    etcPartnerListForAutoComplete,
    handleAutoCompleteChange,
    handleSaveButtonClick,
  ]);

  return (
    <>
      <Modal
        isOpened={opensRegisterSettlementPartnerModal}
        handleClose={handleModalClose}
        modalBody={ModalBody}
      />
    </>
  );
}

export default RegisterSettlementPartnerModal;
