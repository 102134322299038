import { useRef, useState } from "react";

export default function usePreviewDragAndScroll() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [initialX, setInitialX] = useState(0);

  const handleDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!scrollRef.current) return;

    e.preventDefault();
    setIsDragging(true);
    setInitialX(e.pageX + scrollRef.current.scrollLeft);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleDragMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!scrollRef.current) return;

    if (isDragging) {
      scrollRef.current.scrollLeft = initialX - e.pageX;
    }
  };

  return {
    scrollRef,
    handleDragStart,
    handleDragEnd,
    handleDragMove,
  };
}
