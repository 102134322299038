import { Box, Modal, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";

export default function LoadingForParsingFile({
  opensModal,
}: {
  opensModal: boolean;
}) {
  return (
    <Modal open={opensModal}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
      >
        <CircularProgress size={130} />

        <Typography variant="h4" color="#FFFFFF" sx={{ mt: 6 }}>
          매입 파일을 읽는 중입니다.
        </Typography>
      </Box>
    </Modal>
  );
}
