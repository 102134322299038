import { useMemo } from "react";
import { UseFormReset } from "react-hook-form";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { Currency } from "@sellernote/_shared/src/types/common/common";
import {
  ExchangeRate,
  WithdrawalFormListData,
  WithdrawalItemList,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDateToUTCDate } from "@sellernote/_shared/src/utils/common/date";

function useGetWithdrawalData({
  bidAccountPayableId,
  setCurrency,
  setCurrentExchangeRate,
  setBaseExchangeRate,
  handleWithdrawalInvoiceIdChange,
  handleWithdrawalInvoiceDateChange,
  exchangeRateList,
  reset,
  onAccountIdChange,
}: {
  bidAccountPayableId: number;
  setCurrency: (value: Currency) => void;
  setCurrentExchangeRate: (value: number) => void;
  setBaseExchangeRate: (value: number) => void;
  handleWithdrawalInvoiceIdChange: (value: string) => void;
  handleWithdrawalInvoiceDateChange: (date: string | null) => void;
  exchangeRateList: ExchangeRate[];
  reset: UseFormReset<WithdrawalFormListData>;
  onAccountIdChange: (id: number | null) => void;
}) {
  const { data } = TRELLO_BID_QUERY.useGetWithdrawalBasicList({
    params: { bidAccountPayableId },
    onSuccess: (responseData) => {
      // react-hook-form의 기본 데이터를 셋팅 저장된 값이 없다면 빈 배열
      reset({
        withdrawalFormList:
          responseData?.detailList.map((detailItem) => {
            return {
              id: detailItem.id,
              amount: detailItem.amount,
              currency: detailItem.currency,
              finalPrice: detailItem.finalPrice,
              isVAT: detailItem.isVAT,
              itemUnitMeasurement: detailItem.itemUnitMeasurement,
              name: detailItem.name,
              note: detailItem.note,
              totalPrice: detailItem.totalPrice,
              vatPrice: detailItem.vatPrice,
              unitPrice: detailItem.unitPrice,
            };
          }) || [],
      });

      if (responseData?.withdrawalRequest) {
        const withdrawalRequest = responseData.withdrawalRequest;
        // 인보이스 아이디를 설정
        handleWithdrawalInvoiceIdChange(
          withdrawalRequest.withdrawalInvoiceId ?? ""
        );

        // 사용한 통화를 사용
        setCurrency(withdrawalRequest.currency);

        // 현재 환율을 설정
        setCurrentExchangeRate(withdrawalRequest.exchangeRate);

        // 환율이 변경됐는지 확인하는 기본 환율을 설정
        setBaseExchangeRate(withdrawalRequest.exchangeRate);

        onAccountIdChange(withdrawalRequest.financialAccountId ?? 0);

        if (!withdrawalRequest.withdrawalInvoiceDate) {
          handleWithdrawalInvoiceDateChange(null);
          return;
        }

        // 인보이스 날짜를 설정
        handleWithdrawalInvoiceDateChange(
          toFormattedDateToUTCDate(withdrawalRequest.withdrawalInvoiceDate)
        );

        return;
      }
      /** 요청 데이터가 없는 경우 당일 환율을 적용*/
      setCurrentExchangeRate(
        exchangeRateList.find((exchange) => exchange.currency === "USD")
          ?.rate || 0
      );
      setBaseExchangeRate(
        exchangeRateList.find((exchange) => exchange.currency === "USD")
          ?.rate || 0
      );
    },
  });

  /** 저장/출금 요청을 했는지 여부  */
  const isWithdrawalRequested = useMemo(() => {
    if (!data) {
      return false;
    }

    if (!data.withdrawalRequest) {
      return false;
    }

    if (data.withdrawalRequest.isWithdrawalRequested) {
      return true;
    }

    return false;
  }, [data]);

  /** 출금액 항목을 위한 옵션 리스트 데이터 */
  const withdrawalItemList = useMemo(() => {
    if (!data) {
      return [];
    }

    if (data.itemList.length > 0) {
      return data.itemList.map((v: WithdrawalItemList) => {
        return v.name;
      });
    }
    return [];
  }, [data]);

  return {
    isWithdrawalRequested,
    withdrawalItemList,
  };
}

export default useGetWithdrawalData;
