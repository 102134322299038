import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Box } from "@mui/material";

import ADMIN_ZONE_ADDRESS_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ZONE_ADDRESS_QUERY";
import { Port } from "@sellernote/_shared/src/types/common/common";
import {
  AdminBidDetail,
  AdminShipmentInfoForm,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";

import { transPortTypeRadioGroupOptionList } from "../constants";
import Export from "./Export";
import Import from "./Import";

function TransportInfo({
  portList,
  isCTypeIncoterms,
  shipmentDetail,
}: {
  portList: Port[];
  isCTypeIncoterms: boolean;
  shipmentDetail: AdminBidDetail;
}) {
  const { control } = useFormContext<AdminShipmentInfoForm>();

  const endAddress = useWatch({
    control,
    name: "endAddress",
  });

  const startAddress = useWatch({
    control,
    name: "startAddress",
  });

  const { isImport, freightType } = shipmentDetail;

  // 도착지 주소 입력시 API 중복 요청을 피하기 위해 입력 지연
  const debouncedEndAddress = useDebounce(endAddress, 500);
  const debouncedStartAddress = useDebounce(startAddress, 500);

  const { data: zoneAddress } = ADMIN_ZONE_ADDRESS_QUERY.useGetAdminZoneAddress(
    {
      address: isImport
        ? debouncedEndAddress ?? ""
        : debouncedStartAddress ?? "",
    }
  );

  const zoneNameListOptions = useMemo(() => {
    if (!zoneAddress) {
      return [];
    }

    return zoneAddress.map((v) => {
      return v.roadAddress || v.jibunAddress || v.name;
    });
  }, [zoneAddress]);

  /** 출발지 운송타입 */
  const startTypeRadioGroupOption = transPortTypeRadioGroupOptionList.map(
    (option) => {
      if (freightType === "AIR") {
        return {
          ...option,
          /** AIR일 때 해상은 disabled 만약 C 조건이라면 내륙도 disabled */
          disabled:
            option.value === "sea" ||
            (option.value === "inland" && isCTypeIncoterms && isImport),
        };
      }

      return {
        ...option,
        /** FCL, LCL 때 항공은 disabled 만약 C 조건이라면 내륙도 disabled */
        disabled:
          option.value === "air" ||
          (option.value === "inland" && isCTypeIncoterms && isImport),
      };
    }
  );

  /** 도착지 운송타입 */
  const endTypeRadioGroupOption = transPortTypeRadioGroupOptionList.map(
    (option) => {
      if (freightType === "AIR") {
        return {
          ...option,
          /** AIR일 때 해상은 disabled 만약 수입 C 조건이라면 내륙만 선택 가능 */
          disabled:
            option.value === "sea" ||
            (option.value === "air" && isCTypeIncoterms && isImport),
        };
      }

      return {
        ...option,
        /** FCL, LCL 때 항공은 disabled 만약 수입 C 조건이라면 내륙만 선택 가능 */
        disabled:
          option.value === "air" ||
          (option.value === "sea" && isCTypeIncoterms && isImport),
      };
    }
  );

  return (
    <Box>
      {isImport ? (
        <Import
          endAddress={endAddress}
          isCTypeIncoterms={isCTypeIncoterms}
          zoneNameListOptions={zoneNameListOptions}
          zoneAddress={zoneAddress}
          startTypeRadioGroupOption={startTypeRadioGroupOption}
          endTypeRadioGroupOption={endTypeRadioGroupOption}
          portList={portList}
          freightType={freightType}
          shipmentDetail={shipmentDetail}
        />
      ) : (
        <Export
          startAddress={startAddress}
          zoneNameListOptions={zoneNameListOptions}
          zoneAddress={zoneAddress}
          startTypeRadioGroupOption={startTypeRadioGroupOption}
          endTypeRadioGroupOption={endTypeRadioGroupOption}
          portList={portList}
          freightType={freightType}
          shipmentDetail={shipmentDetail}
        />
      )}
    </Box>
  );
}

export default TransportInfo;
