import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  FormLabel,
  Input,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import * as EmailValidator from "email-validator";
import { useRecoilState } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { FORWARDING_ADMIN_BID_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBid";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";

import useSnackbar from "../../../../hooks/useSnackbar";

import ExporterDescModal from "./ExporterDescModal";

type InputType = "companyName" | "personName" | "personPhone" | "personEmail";

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
};

const ExporterDesc = ({ bidDetail }: { bidDetail: AdminBidDetail }) => {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [showsFindExportersModal, setShowsFindExportersModal] = useState(false);

  const [exporterInfo, setExporterInfo] = useRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.ADMIN_BID_EXPORTER_INFO
  );

  const [isEdit, setIsEdit] = useState(false);

  const { mutate: requestExporterInfo } =
    ADMIN_BID_QUERY.useRequestExporterInfo();

  const { mutate: changeShipmentExporterInfo } =
    ADMIN_BID_QUERY.useChangeShipmentExporterInfo();

  const { mutate: changeExportBidDetail } =
    ADMIN_BID_QUERY.useChangeExportBidDetail(bidDetail.id);

  // recoil로 저장된 수출자 정보 초기화
  useEffect(() => {
    return () => {
      setExporterInfo({
        companyName: "",
        personEmail: "",
        personName: "",
        personPhone: "",
      });
    };
  }, [setExporterInfo]);

  const handleExporterInfoRequestClick = useCallback(() => {
    requestExporterInfo(
      {
        pathParams: {
          targetId: bidDetail.id,
          kind: "bid",
        },
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("수출입 정보 입력 요청을 했습니다.");
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: bidDetail.id,
            })
          );
        },
        onError: () => {
          handleSnackbarOpen("요청을 실패했습니다.", "error");
        },
      }
    );
  }, [bidDetail.id, handleSnackbarOpen, queryClient, requestExporterInfo]);

  const handleExporterInfoUpdateClick = useCallback(() => {
    if (!exporterInfo?.companyName) {
      handleSnackbarOpen(
        bidDetail.isImport
          ? "수출자명을 입력해주세요."
          : "수입자명을 입력해주세요.",
        "warning"
      );
      return;
    }

    if (!exporterInfo?.personName) {
      handleSnackbarOpen("담당자 이름을 입력해주세요.", "warning");
      return;
    }

    if (!exporterInfo?.personPhone) {
      handleSnackbarOpen("수출자 번호를 입력해주세요.", "warning");
      return;
    }

    if (!exporterInfo?.personEmail) {
      handleSnackbarOpen("이메일을 입력해주세요.", "warning");
      return;
    }

    if (!EmailValidator.validate(exporterInfo.personEmail)) {
      handleSnackbarOpen("이메일 형식이 아닙니다.", "warning");
      return;
    }

    const successResponseHandler = {
      onSuccess: () => {
        handleSnackbarOpen("수출입 정보를 수정 했습니다.");
        setIsEdit(false);
        queryClient.invalidateQueries(
          ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
            bidId: bidDetail.id,
          })
        );
      },
    };

    // 콘솔이 아닌 의뢰에선 exporterGroup 값을 주지 않음 1로 고정해서 보내기로 협의함.
    if (bidDetail.isImport) {
      changeShipmentExporterInfo(
        {
          bidId: bidDetail.id,
          exporters: [{ ...exporterInfo, exporterGroup: 1 }],
        },
        {
          ...successResponseHandler,
          onError: ({ response }) => {
            if (response?.data?.code === 404) {
              handleSnackbarOpen("의뢰를 찾을 수 없습니다.", "error");
              return;
            }

            if (response?.data?.code === 400) {
              handleSnackbarOpen(
                "수출자 정보 수정이 불가능한 상태입니다.",
                "error"
              );
              return;
            }

            handleSnackbarOpen("요청에 실패했습니다.", "error");
            return;
          },
        }
      );
      return;
    }

    changeExportBidDetail(
      {
        exporterInfo,
      },
      {
        ...successResponseHandler,
        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
          return;
        },
      }
    );
    return;
  }, [
    bidDetail.id,
    bidDetail.isImport,
    changeExportBidDetail,
    changeShipmentExporterInfo,
    exporterInfo,
    handleSnackbarOpen,
    queryClient,
  ]);

  const handleExporterInfoEditCancelClick = useCallback(() => {
    setExporterInfo({
      companyName: bidDetail?.exporterInfo?.companyName || "",
      personName: bidDetail?.exporterInfo?.personName || "",
      personEmail: bidDetail?.exporterInfo?.personEmail || "",
      personPhone: bidDetail?.exporterInfo?.personPhone || "",
    });
    setIsEdit(false);
  }, [
    setExporterInfo,
    bidDetail?.exporterInfo?.companyName,
    bidDetail?.exporterInfo?.personName,
    bidDetail?.exporterInfo?.personEmail,
    bidDetail?.exporterInfo?.personPhone,
  ]);

  const handleExporterInfoEditOpenClick = useCallback(() => {
    setExporterInfo({
      companyName: bidDetail?.exporterInfo?.companyName || "",
      personName: bidDetail?.exporterInfo?.personName || "",
      personEmail: bidDetail?.exporterInfo?.personEmail || "",
      personPhone: bidDetail?.exporterInfo?.personPhone || "",
    });
    setIsEdit(true);
  }, [
    bidDetail?.exporterInfo?.companyName,
    bidDetail?.exporterInfo?.personEmail,
    bidDetail?.exporterInfo?.personName,
    bidDetail?.exporterInfo?.personPhone,
    setExporterInfo,
  ]);

  const handleExporterInputChange = useCallback(
    (inputValue: string, inputType: InputType) => {
      setExporterInfo({
        ...exporterInfo,
        [inputType]: inputValue,
      });
    },
    [exporterInfo, setExporterInfo]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">
          {bidDetail.isImport ? "수출자 정보" : "수입자 정보"}
        </Typography>

        {isEdit ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              sx={{ flex: "0 0 auto" }}
              onClick={() => setShowsFindExportersModal(true)}
            >
              불러오기
            </Button>

            <Button
              variant="contained"
              sx={{ flex: "0 0 auto" }}
              onClick={handleExporterInfoUpdateClick}
            >
              확인
            </Button>

            <Button
              color="error"
              variant="contained"
              sx={{ flex: "0 0 auto" }}
              onClick={handleExporterInfoEditCancelClick}
            >
              취소
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", gap: 1 }}>
            {APP_NAME === "shipda-admin" && bidDetail.isImport && (
              <Button
                variant="contained"
                sx={{ flex: "0 0 auto" }}
                onClick={handleExporterInfoRequestClick}
              >
                PUSH 메일 발송
              </Button>
            )}

            {APP_NAME === "shipda-admin" && (
              <Button
                variant="contained"
                sx={{ flex: "0 0 auto" }}
                onClick={handleExporterInfoEditOpenClick}
              >
                {bidDetail.isImport ? "수출자 정보 입력" : "수입자 정보 입력"}
              </Button>
            )}
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          columnGap: 1,
        }}
      >
        <Box sx={boxLayoutStyle}>
          <FormLabel htmlFor="companyName" sx={{ flex: "0 0 auto" }}>
            {bidDetail.isImport ? "수출자명" : "수입자 회사명"}:
          </FormLabel>

          {isEdit ? (
            <Input
              fullWidth
              id="companyName"
              value={exporterInfo?.companyName}
              onChange={(e) =>
                handleExporterInputChange(e.target.value, "companyName")
              }
            />
          ) : (
            <Typography component="span">
              {bidDetail?.exporterInfo?.companyName || ""}
            </Typography>
          )}
        </Box>

        <Box sx={boxLayoutStyle}>
          <FormLabel htmlFor="personName" sx={{ flex: "0 0 auto" }}>
            담당자 이름:
          </FormLabel>
          {isEdit ? (
            <Input
              fullWidth
              id="personName"
              value={exporterInfo?.personName}
              onChange={(e) =>
                handleExporterInputChange(e.target.value, "personName")
              }
            />
          ) : (
            <Typography component="span">
              {bidDetail?.exporterInfo?.personName || ""}
            </Typography>
          )}
        </Box>

        <Box sx={boxLayoutStyle}>
          <FormLabel htmlFor="personPhone" sx={{ flex: "0 0 auto" }}>
            연락처:
          </FormLabel>
          {isEdit ? (
            <Input
              fullWidth
              id="personPhone"
              placeholder="-없이 입력해주세요"
              value={exporterInfo?.personPhone}
              onChange={(e) =>
                handleExporterInputChange(e.target.value, "personPhone")
              }
            />
          ) : (
            <Typography component="span">
              {bidDetail?.exporterInfo?.personPhone || ""}
            </Typography>
          )}
        </Box>

        <Box sx={boxLayoutStyle}>
          <FormLabel htmlFor="personEmail" sx={{ flex: "0 0 auto" }}>
            이메일:
          </FormLabel>
          {isEdit ? (
            <Input
              fullWidth
              id="personEmail"
              className="input"
              value={exporterInfo?.personEmail}
              onChange={(e) =>
                handleExporterInputChange(e.target.value, "personEmail")
              }
            />
          ) : (
            <Typography component="span">
              {bidDetail?.exporterInfo?.personEmail || ""}
            </Typography>
          )}
        </Box>
      </Box>

      {showsFindExportersModal && (
        <ExporterDescModal
          isImport={bidDetail.isImport}
          teamId={bidDetail.teamId}
          showsFindExportersModal={showsFindExportersModal}
          setShowsFindExportersModal={setShowsFindExportersModal}
        />
      )}
    </Box>
  );
};

export default ExporterDesc;
