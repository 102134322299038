import { useCallback, useEffect } from "react";
import { Modal, Spin } from "antd";
import { useSetRecoilState } from "recoil";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import SHIP_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/SHIP_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import {
  ExportTrelloList,
  FetchTrelloListParams,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { useWindowResize } from "@sellernote/_shared/src/utils/common/hook";

import ShipmentCommentList from "../../../TrelloDetail/components/ShipmentCommentList";

import BasicInfo from "../../../_components/detail/BasicInfo";
import Profit from "../../../_components/detail/Profit";
import PurchaseManagement from "../../../_components/detail/PurchaseManagement";
import SalesManagement from "../../../_components/detail/SalesManagement";
import ShipmentSchedule from "../../../_components/detail/ShipmentSchedule";
import ShipNameManagement from "../../../_components/detail/ShipNameManagement";
import UploadDocuments from "../../../_components/detail/UploadDocuments";
import useRefreshTrelloListForDetailModal from "../../../_hooks/useRefreshTrelloListForDetailModal";

function DetailModal({
  showsExportTrelloDetailModal,
  setShowsExportTrelloDetailModal,
  bidId,
  trelloListData,
  fetchTrelloListParams,
}: {
  showsExportTrelloDetailModal: boolean;
  setShowsExportTrelloDetailModal: (value: boolean) => void;
  bidId: number | undefined;
  trelloListData?: ExportTrelloList[];
  fetchTrelloListParams?: FetchTrelloListParams;
}) {
  const [windowWidth] = useWindowResize();

  // 모달의 넓이를 최대한 넓게 해달라는 요청 반영
  const modalWidth = windowWidth - 50;

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const { data: trelloDetailData } = TRELLO_BID_QUERY.useGetExportTrelloDetail({
    bidId,
  });

  const { data: shipList } = SHIP_QUERY.useGetShipList();

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: trelloDetailData?.locale,
  });

  const { partnerList } = PARTNER_QUERY.useGetPartnerList({
    page: 0,
    enabled: true,
    pageFlag: false,
    region: trelloDetailData?.locale ?? "KR",
  });

  const { refreshTrelloListForDetailModal } =
    useRefreshTrelloListForDetailModal({
      trelloListData,
      fetchTrelloListParams,
      isImport: false,
      trelloDetailData,
    });

  const handleCloseModal = useCallback(() => {
    setShowsExportTrelloDetailModal(false);

    refreshTrelloListForDetailModal();
  }, [refreshTrelloListForDetailModal, setShowsExportTrelloDetailModal]);

  useEffect(() => {
    setTrelloCardId(undefined);
  }, [setTrelloCardId]);

  if (!trelloDetailData || !shipList || !exchangeRateList || !partnerList) {
    return (
      <Modal
        destroyOnClose={true}
        style={{ fontFamily: "Roboto" }}
        bodyStyle={{ height: 800, overflow: "scroll" }}
        visible={showsExportTrelloDetailModal}
        width={modalWidth}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Spin />
      </Modal>
    );
  }

  return (
    <Modal
      destroyOnClose={true}
      style={{ fontFamily: "Roboto" }}
      bodyStyle={{ height: 800, overflow: "scroll" }}
      visible={showsExportTrelloDetailModal}
      width={modalWidth}
      onCancel={handleCloseModal}
      footer={null}
    >
      <BasicInfo trelloDetail={trelloDetailData} partnerList={partnerList} />

      <ShipNameManagement trelloDetail={trelloDetailData} />

      <ShipmentSchedule trelloDetail={trelloDetailData} />

      <UploadDocuments trelloDetail={trelloDetailData} />

      <SalesManagement trelloDetailData={trelloDetailData} isExport={true} />

      <PurchaseManagement
        trelloDetailData={trelloDetailData}
        partnerList={partnerList}
        exchangeRateList={exchangeRateList}
      />

      <Profit trelloDetail={trelloDetailData} />

      <ShipmentCommentList trelloDetailData={trelloDetailData} />
    </Modal>
  );
}

export default DetailModal;
