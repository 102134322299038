import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";

import Layout from "../../containers/Layout";

import AdminUserTable from "./AdminUserTable";

function AdminUserList() {
  const { data: adminUserList } = ADMIN_USER_QUERY.useGetAdminUserList();

  return (
    <Layout>
      <AdminUserTable adminUserList={adminUserList} />
    </Layout>
  );
}

export default AdminUserList;
