import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { HBLInfo } from "@sellernote/_shared/src/types/forwarding/trello";

import useSnackbar from "../../../../../../../../hooks/useSnackbar";

function useSaveHBLRequest({ shipmentId }: { shipmentId: number }) {
  const { mutate: saveHBL, isLoading: isSaveRequestLoading } =
    TRELLO_BID_QUERY.useSaveHBL({
      shipmentId,
    });

  const { mutate: tempSaveHBL, isLoading: isTempSaveRequestLoading } =
    TRELLO_BID_QUERY.useTempSaveHBL({
      shipmentId,
    });

  const { watch } = useFormContext<HBLInfo>();

  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const formState = watch();

  const commonPayload = useMemo(() => {
    const {
      shipperName,
      shipperAddress,
      consigneeName,
      consigneeAddress,
      notifyParty,
      notifyPartyAddress,
      transportMode,
      origin,
      dest,
      pol,
      pod,
      containers,
      packingType,
      itemName,
      unknownClause,
      weight,
      cbm,
      unitSupply,
      blType,
      circulation,
      desiredAt,
      isAgree,
      partnerAddress,
      fullETD,
      partnerName,
    } = formState;

    return {
      shipperName,
      shipperAddress,
      consigneeName,
      consigneeAddress,
      notifyParty,
      notifyPartyAddress,
      transportMode,
      origin,
      dest,
      pol,
      pod,
      containers,
      packingType,
      itemName,
      unknownClause,
      weight,
      cbm,
      unitSupply,
      blType,
      circulation,
      desiredAt,
      isAgree,
      partnerAddress,
      fullETD,
      partnerName,
    };
  }, [formState]);

  const handleRequestSuccess = async (snackbarMessage: string) => {
    handleSnackbarOpen(snackbarMessage);

    await Promise.all([
      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getHBLInfo({ shipmentId })
      ),
      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({
          bidId: shipmentId,
        })
      ),
    ]);
  };

  const handleTemporarySaveClick = () => {
    tempSaveHBL(
      { ...commonPayload },
      {
        onSuccess: () => {
          handleRequestSuccess("BL이 임시저장됐습니다.");
        },

        onError: () => {
          handleSnackbarOpen("임시저장을 실패했습니다.", "error");
        },
      }
    );
  };

  const handleSaveClick = () => {
    /** 의뢰에서 freightPaymentType값이 null인 경우가 있음 */
    if (!formState.freightPaymentType) {
      handleSnackbarOpen(
        "의뢰 상세에서 지급 유형을 먼저 설정해주세요.",
        "warning"
      );
      return;
    }

    saveHBL(
      { ...commonPayload },
      {
        onSuccess: () => {
          handleRequestSuccess("BL이 저장됐습니다.");
        },

        onError: () => {
          handleSnackbarOpen("저장을 실패했습니다.", "error");
        },
      }
    );
  };

  return {
    handleTemporarySaveClick,
    handleSaveClick,
    isSaveRequestLoading,
    isTempSaveRequestLoading,
  };
}

export default useSaveHBLRequest;
