import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { BLType } from "@sellernote/_shared/src/types/forwarding/trello";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";

/**
 * 화물 유형에 따라 BL 유형을 반환하는 유틸 함수
 *   - 적하목록 제출, 매입자료 입력 모달에서 사용
 *
 * @param freightType 화물 유형
 * @returns BL 유형
 */
const getBLTypeByFreightType = (
  freightType: FreightType
): Partial<Record<BLType, string>> =>
  ({
    FCL: {
      ALL: "MBL/HBL",
      DirectMBL: "MBL Direct",
    },
    LCL: {
      ALL: "MBL/HBL",
      HBL: "HBL",
    },
    AIR: {
      ALL: "MAWB/HAWB",
      DirectMBL: "MAWB Direct",
    },
  }[freightType]);

/**
 * 화물 유형에 따라 BL 정보를 반환하는 유틸 함수
 *   - 적하목록 제출, 매입자료 입력 모달에서 사용
 *
 * 사용시 상위 컴포넌트에 whiteSpace: "pre-line" 적용 필요
 *
 * @param freightType 화물 유형
 * @param mBL MBL
 * @param hBL HBL
 * @returns 입력된 BL 정보
 */
const getBLInfoByFreightType = ({
  freightType,
  mBL,
  hBL,
}: {
  freightType: FreightType;
  mBL: string | null;
  hBL: string | null;
}): Partial<Record<BLType, string>> => {
  const formattedMBl = replaceEmptyToDash(mBL);
  const formattedHBl = replaceEmptyToDash(hBL);

  return {
    FCL: {
      ALL: `MBL: ${formattedMBl} \n HBL: ${formattedHBl}`,
      DirectMBL: `MBL: ${formattedMBl}`,
    },
    LCL: {
      ALL: `MBL: ${formattedMBl} \n HBL: ${formattedHBl}`,
      HBL: `HBL: ${formattedHBl}`,
    },
    AIR: {
      ALL: `MAWB: ${formattedMBl} \n HAWB: ${formattedHBl}`,
      DirectMBL: `MAWB: ${formattedMBl}`,
    },
  }[freightType];
};

export { getBLInfoByFreightType, getBLTypeByFreightType };
