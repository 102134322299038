import React, { useEffect, useState } from "react";
import { Box, Divider } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { TrelloBidManagement } from "@sellernote/_shared/src/types/forwarding/trello";

import useSnackbar from "../../../../../../../../../hooks/useSnackbar";
import { ContainerFormChangeProps } from "../types";

import EditAndSaveButton from "./EditAndSaveButton";
import FormHeader from "./FormHeader";
import FormList from "./FormList";
import RequestButton from "./RequestButton";

export default function CargoManifestInfoForm({
  form,
  cargoManifestInfo,
  onFormChange,
  onContainerFormChange,
  onEstimatedTimeChange,
  shipmentId,
  isImport,
  BRN,
  management,
  freightType,
}: {
  form: CargoManifestInfoProps;
  cargoManifestInfo: CargoManifestInfoProps;
  onFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onContainerFormChange: ({
    index,
    key,
    value,
  }: ContainerFormChangeProps) => void;
  onEstimatedTimeChange: (value: string | null, isImport: boolean) => void;
  shipmentId: number;
  isImport: boolean;
  BRN: string;
  management: TrelloBidManagement;
  freightType: FreightType;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [canSubmit, setCanSubmit] = useState(
    form.status === null || form.status === "fail"
  );

  /** 폼이 수정 가능한 상태인지. true일 경우 TextField의 disabled 속성이 false로 변함 */
  const [isEditMode, setIsEditMode] = useState(false);

  /**
   * 이미 제출됐거나, 제출 대기중인 적하목록인 경우 경고 모달을 띄움
   */
  useEffect(() => {
    if (!canSubmit) {
      handleSnackbarOpen(
        "이미 제출됐거나, 제출 대기중인 적하목록입니다.",
        "warning"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <FormHeader
          BRN={BRN}
          management={management}
          freightType={freightType}
        />

        <Box>
          <EditAndSaveButton
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            shipmentId={shipmentId}
            form={form}
            cargoManifestInfo={cargoManifestInfo}
            canSubmit={canSubmit}
            isImport={isImport}
          />

          <RequestButton
            form={form}
            shipmentId={shipmentId}
            isEditMode={isEditMode}
            canSubmit={canSubmit}
            setCanSubmit={setCanSubmit}
            isImport={isImport}
          />
        </Box>
      </Box>

      <Divider />

      <FormList
        form={form}
        onFormChange={onFormChange}
        onContainerFormChange={onContainerFormChange}
        onEstimatedTimeChange={onEstimatedTimeChange}
        isEditMode={isEditMode}
        isImport={isImport}
      />
    </Box>
  );
}
