import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import ADMIN_SLACK_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_SLACK_QUERY";

import Modal from "../../../../../../../../components/Modal";

const CancelWithdrawModal = ({
  isOpen,
  setShowCancelWithdrawModal,
  partnerId,
  shipmentId,
}: {
  isOpen: boolean;
  setShowCancelWithdrawModal: React.Dispatch<React.SetStateAction<boolean>>;
  partnerId: number;
  shipmentId: number;
}) => {
  const [cancelReason, setCancelReason] = useState("직접 입력");
  const [etcReason, setEtcReason] = useState("");

  const {
    mutate: cancelWithdraw,
    ResponseHandler: ResponseHandlerOfUseCancelWithdraw,
  } = ADMIN_SLACK_QUERY.useCancelWithdraw({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setShowCancelWithdrawModal(false);
      },
      customizeMessage: () => ({
        title: "출금액 취소 슬랙 알람을 요청했습니다.",
      }),
    },
  });

  const handleCancelWithDrawClick = useCallback(() => {
    cancelWithdraw({
      bidId: shipmentId,
      partnerCompanyId: partnerId,
      cancelReason: cancelReason === "직접 입력" ? etcReason : cancelReason,
    });
  }, [cancelWithdraw, shipmentId, partnerId, cancelReason, etcReason]);

  return (
    <>
      <Modal
        isOpened={isOpen}
        handleClose={() => setShowCancelWithdrawModal(false)}
        modalBody={
          <Box>
            <Typography variant="h6">출금요청 취소 사유입력</Typography>

            <FormControl fullWidth size="small">
              <Select
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  mt: 2,
                }}
              >
                <MenuItem value="직접 입력">{"직접 입력"}</MenuItem>
                <MenuItem value="항목 추가">{"항목 추가"}</MenuItem>
                <MenuItem value="항목 삭제">{"항목 삭제"}</MenuItem>
                <MenuItem value="출금요청 실수">{"출금요청 실수"}</MenuItem>
              </Select>
            </FormControl>

            {cancelReason === "직접 입력" && (
              <TextField
                size="small"
                fullWidth
                placeholder="취소 요청 사유를 입력하세요"
                onChange={(e) => setEtcReason(e.target.value)}
                value={etcReason}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  mt: 2,
                }}
              />
            )}

            <Button
              fullWidth
              disabled={cancelReason === "직접 입력" && !etcReason}
              onClick={handleCancelWithDrawClick}
              sx={{ mt: 2 }}
              variant="contained"
            >
              요청하기
            </Button>
          </Box>
        }
      />

      {ResponseHandlerOfUseCancelWithdraw}
    </>
  );
};

export default CancelWithdrawModal;
