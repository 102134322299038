import { useQueryClient } from "react-query";
import { Box, Button, Typography } from "@mui/material";

import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

import useSnackbar from "../../../../../../../../../hooks/useSnackbar";

import Modal from "../../../../../../../../../components/Modal";

export default function DeleteModal({
  shipmentId,
  attachmentId,
  onAttachmentIndexChange,
  showsDeleteModal,
  setShowsDeleteModal,
}: {
  shipmentId: number;
  attachmentId: number;
  onAttachmentIndexChange: (index: number) => void;
  showsDeleteModal: boolean;
  setShowsDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();

  const { handleSnackbarOpen } = useSnackbar();

  const { mutate: deleteFile } = ADMIN_FILE_QUERY.useDeleteFile({
    onSuccess: () => {
      onAttachmentIndexChange(0);
      handleSnackbarOpen("파일을 성공적으로 삭제했습니다.");
      setShowsDeleteModal(false);

      return Promise.all([
        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({ bidId: shipmentId })
        ),

        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail()),

        queryClient.invalidateQueries(
          CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.getCustomsTrelloBidDetail({
            bidId: shipmentId,
          })
        ),
      ]);
    },
  });

  return (
    <Modal
      isOpened={showsDeleteModal}
      handleClose={() => setShowsDeleteModal(false)}
      modalBody={
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Typography component="span" fontWeight={"bold"}>
            파일을 삭제하시겠습니까?
          </Typography>

          <Button
            variant="contained"
            fullWidth
            onClick={() => deleteFile({ attachmentId })}
          >
            삭제
          </Button>
        </Box>
      }
    />
  );
}
