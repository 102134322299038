import { atom } from "jotai";

import { GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ_SEARCH_KIND } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminSettlement";

import { MultiSearchWithTermList } from "../../types/common";
import { atomWithReset } from "jotai/utils";

const PURCHASE_TABLE_SEARCH_WITH_TERM_LIST = atomWithReset<
  MultiSearchWithTermList<GET_ADMIN_SETTLEMENT_PURCHASE_LIST_REQ_SEARCH_KIND>[]
>([]);

/**
 * 발행되지 않은 계산서의 발행요청 취소 시 슬랙알림 전송하지 않고 매출관리 링크로 이동 (#14041)
 * 매출 관리로 이동 후 해당 의뢰번호로 필터링 하기 위해 저장
 */
const SALES_MANAGEMENT_SHIPMENT_ID_FILTER = atom<number | undefined>(undefined);

export default {
  PURCHASE_TABLE_SEARCH_WITH_TERM_LIST,
  SALES_MANAGEMENT_SHIPMENT_ID_FILTER,
};
