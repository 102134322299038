import { useState } from "react";
import { useQueryClient } from "react-query";
import { useAtomValue } from "jotai";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";

import useSnackbar from "../../../../../../../../../hooks/useSnackbar";
import { getScheduleListForPayload } from "../../utils";

import { FORWARDING_ADMIN_TRELLO_ATOMS } from "../../../../../../../../../jotaiStates/trello";

function useHandleRegisterSchedule({
  shipmentId,
  projectStatus,
  isImport,
}: {
  isFirstScheduleEmailSend: boolean;
  shipmentId: number;
  projectStatus?: BidProjectStatus;
  isImport?: boolean;
}) {
  const [opensRegisterScheduleModal, setOpensRegisterScheduleModal] =
    useState(false);

  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutate: registerShipmentSchedule } =
    TRELLO_BID_QUERY.useRegisterShipmentSchedule();

  const shipmentScheduleList = useAtomValue(
    FORWARDING_ADMIN_TRELLO_ATOMS.SHIPMENT_SCHEDULE_LIST
  );

  /** 스케줄 입력 */
  const handleScheduleRegister = (sendsEmail: boolean) => {
    const payload = {
      routes: getScheduleListForPayload(shipmentScheduleList),
      sendEmail: sendsEmail,
      pathParams: { shipmentId },
    };

    /** 수입운송은 스케줄 컨택 중일 때만 스케줄 입력이 가능 */
    if (isImport && projectStatus !== "contactingPartner") {
      handleSnackbarOpen(
        "스케줄 컨택 중인 경우 스케줄 입력이 가능합니다.",
        "warning"
      );
      return;
    }

    /** 수출운송은 화물준비 중일 때만 스케줄 입력이 가능 */
    if (!isImport && projectStatus !== "waitingFreight") {
      handleSnackbarOpen(
        "화물 준비 중인 경우 스케줄 입력이 가능합니다.",
        "warning"
      );
      return;
    }

    const handleSuccess = () => {
      handleSnackbarOpen("스케줄 입력에 성공했습니다.", "success");
      setOpensRegisterScheduleModal(false);

      return Promise.all([
        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getShipmentScheduleDetail({ shipmentId })
        ),
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail()),
      ]);
    };

    registerShipmentSchedule(payload, { onSuccess: handleSuccess });
  };

  return {
    handleScheduleRegister,
    opensRegisterScheduleModal,
    setOpensRegisterScheduleModal,
  };
}

export default useHandleRegisterSchedule;
