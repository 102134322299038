import { Box, Button, Typography } from "@mui/material";

import Modal from "../../../../../../../components/Modal";

export default function OverlayConfirmModal({
  opensModal,
  onModalClose,
  onParsedWithdrawalFormGet,
}: {
  opensModal: boolean;
  onModalClose: () => void;
  onParsedWithdrawalFormGet: () => void;
}) {
  return (
    <Modal
      isOpened={opensModal}
      handleClose={onModalClose}
      modalBody={
        <Box display={"flex"} flexDirection={"column"} textAlign={"center"}>
          <Typography color="error">
            현재 매입 항목이 삭제되고, 해당 파일로부터 읽어온 데이터로
            변경됩니다.
          </Typography>

          <br />

          <Typography>그래도 진행하시겠습니까?</Typography>

          <Button
            variant="contained"
            onClick={onParsedWithdrawalFormGet}
            sx={{ mt: 3 }}
          >
            확인
          </Button>
        </Box>
      }
    />
  );
}
