import { useMemo } from "react";

import SHIP_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/SHIP_QUERY";

function useShipNameOptionList({
  includesTBDOption,
}: {
  includesTBDOption: boolean;
}) {
  const { data: shipNameData } = SHIP_QUERY.useGetShipList();

  const shipNameOptionList = useMemo(() => {
    if (!shipNameData?.list) return [{ IMO: 0, value: "", label: "" }];

    /** 콘솔 의뢰, LCL의뢰의 경우 TBD 옵션 추가 */
    const TBDOption = { IMO: null, value: "TBD", label: "TBD" };

    const optionList = shipNameData.list.map((v) => {
      return { IMO: v.IMO, value: v.name, label: v.name };
    });

    return includesTBDOption ? [TBDOption, ...optionList] : optionList;
  }, [includesTBDOption, shipNameData?.list]);

  return {
    shipNameOptionList,
  };
}

export default useShipNameOptionList;
