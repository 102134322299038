import { useMemo } from "react";
import { Box } from "@mui/material";

import { GET_ADMIN_BID_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import { GET_CUSTOMS_ADMIN_BID_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/customsAdminBid";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";

import Layout from "../../../containers/Layout";

import ShipmentTable from "../ShipmentTable";

/**
 * 쉽다 어드민과 파트너 어드민에서 선적계획의뢰 테이블를 같이 사용하기 위한 커스텀 훅
 */
export default function useAdminShipmentTable({
  bidListData,
  adminUserList,
  adminDataForTableFilter,
  TablePanel,
  TableHeadPanel,
  refetchBidListData,
  pagination,
  isLoadingBidList,
  isLoadingForGetAdminUserList,
  filterDataReset,
}: {
  bidListData:
    | GET_ADMIN_BID_LIST_RES
    | GET_CUSTOMS_ADMIN_BID_LIST_RES
    | undefined;
  adminUserList: ForwardingAdminUserListItem[] | undefined;
  adminDataForTableFilter:
    | {
        text: string;
        value: number;
      }[]
    | undefined;
  TablePanel: JSX.Element;
  TableHeadPanel: {
    ForwardingManagerFilter: JSX.Element;
    FreightAndIncotermsHeadFilter: JSX.Element;
  };
  refetchBidListData?: () => void;
  pagination: {
    rowsPerPageOptions: number[];
    totalCount: number;
    perPage: number;
    setPerPage: (value: number) => void;
    currentPage: number;
    setCurrentPage: (value: number) => void;
  };
  isLoadingBidList?: boolean;
  isLoadingForGetAdminUserList?: boolean;
  filterDataReset?: () => void;
}) {
  const AdminShipmentTable = useMemo(() => {
    return (
      <Layout title={"선적계획 의뢰"}>
        <Box sx={{ p: 1, pt: 1.5, background: "#fff" }}>{TablePanel}</Box>

        <Box sx={{ p: 1, background: "#fff" }}>
          <ShipmentTable
            adminFilterArray={adminDataForTableFilter}
            adminData={adminUserList}
            bidList={bidListData?.list}
            TableHeadPanel={TableHeadPanel}
            refetchBidListData={refetchBidListData}
            pagination={pagination}
            isLoadingBidList={isLoadingBidList}
            isLoadingForGetAdminUserList={isLoadingForGetAdminUserList}
            filterDataReset={filterDataReset}
          />
        </Box>
      </Layout>
    );
  }, [
    TablePanel,
    adminDataForTableFilter,
    adminUserList,
    bidListData?.list,
    TableHeadPanel,
    refetchBidListData,
    pagination,
    isLoadingBidList,
    isLoadingForGetAdminUserList,
    filterDataReset,
  ]);

  return { AdminShipmentTable };
}
