import { useQueryClient } from "react-query";
import { useAtomValue } from "jotai";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";

import useSnackbar from "../../../../../../../../../hooks/useSnackbar";
import {
  checkOnlyVoyageNoChanged,
  getScheduleListForPayload,
} from "../../utils";

import { FORWARDING_ADMIN_TRELLO_ATOMS } from "../../../../../../../../../jotaiStates/trello";

export default function useCheckFormValidBeforeSave({
  shipmentId,
  freightType,
  setShowsScheduleChangeReasonModal,
  setOpensRegisterScheduleModal,
}: {
  shipmentId: number;
  freightType: FreightType;
  setShowsScheduleChangeReasonModal: (val: boolean) => void;
  setOpensRegisterScheduleModal: (val: boolean) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const prevShipmentSchedule = useAtomValue(
    FORWARDING_ADMIN_TRELLO_ATOMS.PREVIOUS_SHIPMENT_SCHEDULE_LIST
  );

  const shipmentScheduleList = useAtomValue(
    FORWARDING_ADMIN_TRELLO_ATOMS.SHIPMENT_SCHEDULE_LIST
  );

  const { mutate: updateShipmentSchedule } =
    TRELLO_BID_QUERY.useUpdateShipmentSchedule();

  const handleSuccess = () => {
    /** 성공시 스낵바 노출 */
    handleSnackbarOpen("항차 변경이 완료됐습니다.", "success");

    return Promise.all([
      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getShipmentScheduleDetail({ shipmentId })
      ),
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail()),
    ]);
  };

  const validateFormAndSave = ({
    opensScheduleReasonModal,
  }: {
    opensScheduleReasonModal: boolean;
  }) => {
    const lastIndex = shipmentScheduleList.length - 1;

    /** 구간에 모선이 존재하지 않는 경우 Alert (항공은 제외) */
    if (
      !shipmentScheduleList.every((form) => Boolean(form.shipName)) &&
      freightType !== "AIR"
    ) {
      handleSnackbarOpen(
        "모선이 공란인 구간이 있는지 확인해주세요.",
        "warning"
      );
      return;
    }

    /** 첫 구간의 출발 일정이 없으면 Alert */
    if (!(shipmentScheduleList[0].ETD || shipmentScheduleList[0].ATD)) {
      handleSnackbarOpen("첫 구간의 출발 일정을 입력해주세요.", "warning");
      return;
    }

    /** 각 구간에 도착 일정이 없으면 Alert */
    if (!shipmentScheduleList.every((form) => Boolean(form.ETA || form.ATA))) {
      handleSnackbarOpen("모든 구간의 도착 일정을 입력해주세요.", "warning");
      return;
    }

    /**
     * 전 구간의 도착지 / 후 구간의 출발지가 동일한지 확인
     * 폼이 하나밖에 없을 땐 검증 X
     */
    if (
      shipmentScheduleList.length > 1 &&
      !shipmentScheduleList.every((form, index) => {
        return (
          index === lastIndex ||
          (Boolean(form?.arrivalPort.id) &&
            Boolean(shipmentScheduleList[index + 1]?.departurePort.id) &&
            form?.arrivalPort.id ===
              shipmentScheduleList[index + 1]?.departurePort.id)
        );
      })
    ) {
      handleSnackbarOpen(
        "이전 구간의 도착지와 다음 구간의 출발지가 동일한지 확인해주세요.",
        "warning"
      );
      return;
    }

    const isOnlyVoyageNoChanged = checkOnlyVoyageNoChanged(
      shipmentScheduleList,
      prevShipmentSchedule
    );

    /** 항차만 변경된 경우, 변경 사유 모달 없이 스케줄 변경 API를 요청. */
    if (isOnlyVoyageNoChanged && opensScheduleReasonModal) {
      updateShipmentSchedule(
        {
          routes: getScheduleListForPayload(shipmentScheduleList),
          sendEmail: false,
          pathParams: { shipmentId },
        },
        { onSuccess: handleSuccess }
      );
      return;
    }

    /** 유효성 검증에 통과하고, 스케줄 변경의 경우 변경 사유 입력 모달을 노출 */
    if (opensScheduleReasonModal) {
      setShowsScheduleChangeReasonModal(true);
      return;
    }

    setOpensRegisterScheduleModal(true);
  };

  return {
    validateFormAndSave,
  };
}
