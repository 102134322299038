import { Divider, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { blue, common } from "@mui/material/colors";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { TrelloBidManagement } from "@sellernote/_shared/src/types/forwarding/trello";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";

import { getBLTypeByFreightType } from "../../../../../../../../../../utils/BL";
import { getBLInfoByFreightType } from "../../../../../../../../../../utils/BL";

export default function FormHeader({
  BRN,
  management,
  freightType,
}: {
  BRN: string;
  management: TrelloBidManagement;
  freightType: FreightType;
}) {
  const { BLType, mBL, hBL } = management;

  const BLTypeRecord = getBLTypeByFreightType(freightType);
  const BLInfoRecord = getBLInfoByFreightType({ freightType, mBL, hBL });

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography fontWeight={"bold"} className="sads">
        적하목록 신고 정보
      </Typography>

      <Tooltip
        title={
          <Box className="sads" sx={{ whiteSpace: "pre-line" }}>
            <Typography className="sads">
              사업자등록번호: {replaceEmptyToDash(BRN)}
            </Typography>

            <Divider className="sads" color={common.white} sx={{ mb: 1 }} />

            <Typography className="sads">
              BL 유형: {BLTypeRecord[BLType] ?? "-"}
            </Typography>

            <Typography className="sads">
              {BLInfoRecord[BLType] ?? "입력된 BL 정보가 없습니다."}
            </Typography>
          </Box>
        }
        className="sads"
      >
        <Typography color={blue[600]} sx={{ ml: 2 }} className="sads">
          [추가 정보]
        </Typography>
      </Tooltip>
    </Box>
  );
}
