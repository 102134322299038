import { atom } from "recoil";

import { GET_ADMIN_BID_LIST_FILTER } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";

// key에 file structure(package이름 포함)를 반영하여 unique가 보장되도록 한다
const KEY_PREFIX = "_shared-for-forwarding-admin/filter/atoms";

const ADMIN_BID_FILTER_LIST = atom<GET_ADMIN_BID_LIST_FILTER>({
  key: `${KEY_PREFIX}/ADMIN_BID_FILTER_LIST`,
  default: {},
});

const ADMIN_REGION_FILTER = atom<SellernoteAppRegion>({
  key: `${KEY_PREFIX}/ADMIN_REGION_FILTER`,
  default: "KR",
});

const ADMIN_ORDER_FILTER_LIST = atom<GET_ADMIN_BID_LIST_FILTER>({
  key: `${KEY_PREFIX}/ADMIN_ORDER_FILTER_LIST`,
  default: {},
});

const ADMIN_ORDER_FILTER_LIST_NOT_YET = atom<GET_ADMIN_BID_LIST_FILTER>({
  key: `${KEY_PREFIX}/ADMIN_ORDER_FILTER_LIST_NOT_YET`,
  default: {},
});

export default {
  ADMIN_BID_FILTER_LIST,
  ADMIN_REGION_FILTER,
  ADMIN_ORDER_FILTER_LIST,
  ADMIN_ORDER_FILTER_LIST_NOT_YET,
};
