import { atom } from "jotai";

import { GET_ADMIN_TEAM_LIST_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminTeam";

const TEAM_TABLE_FILTER_DATA = atom<GET_ADMIN_TEAM_LIST_REQ>({
  page: 0,
  perPage: 25,
});

export default {
  TEAM_TABLE_FILTER_DATA,
};
