import { useMemo } from "react";
import { Box } from "@mui/material";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import {
  TrelloBidList,
  TrelloIssueFilter,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  checkETAExpires,
  checkIfETDExpiresWhenScheduling,
  checkIsBlNumberHasRegistered,
  checkIsCustomsPartnerHasRegistered,
  checkIsInlandFarePaymentRequest,
  checkIsInlandPartnerHasRegistered,
} from "@sellernote/_shared/src/utils/forwarding/admin/trello";

import { TrelloCardRenewOpCheckPointProps } from "../../../types/trello";
import { getTrelloCardTitle } from "../utils";
import {
  checkDeadlineFreeTime,
  checkScheduleUpdate,
  checkStatusUpdate,
  filterByTrelloCardIssue,
  sortByShipmentScheduleDateDesc,
} from "../utils";

import CustomStatusChip from "../_components/list/CustomStatusChip";
import TrelloCardCheckBox from "../_components/list/TrelloCardCheckBox";
import TrelloCardCommonBody from "../_components/list/TrelloCardCommonBody";
import { CardListData } from "../_components/list/TrelloCardList";

export default function useImportTrelloList({
  trelloListData,
  handleTrelloCardClick,
  handleTrelloCardOpCheckPoint,
  sessionStorageTrelloIssueFilter,
}: {
  trelloListData: TrelloBidList[];
  handleTrelloCardClick: (cardData: TrelloBidList) => void;
  handleTrelloCardOpCheckPoint: ({
    shipmentId,
    isChecked,
    key,
    isFinished,
    isImport,
  }: TrelloCardRenewOpCheckPointProps) => void;
  sessionStorageTrelloIssueFilter: TrelloIssueFilter | null;
}) {
  const importCardListData: CardListData[] = useMemo(() => {
    return [
      {
        lineKey: "1",
        lineTitle: "수출자 컨택 전",
        cards: trelloListData
          .filter((v: TrelloBidList) => {
            return v.projectStatus === "beforeContactPartner";
          })
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullETD"))
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              badge: {
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
              },
              freightType: `${v.freightType}/${v.incoterms}`,
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  <CustomStatusChip status={v.management.customsStatus} />
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "2",
        lineTitle: "수출자 컨택 중",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "contactingPartner")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullETD"))
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              badge: {
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
              },
              freightType: `${v.freightType}/${v.incoterms}`,
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  <CustomStatusChip status={v.management.customsStatus} />

                  <TrelloCardCheckBox label="스케줄 안내" />

                  <TrelloCardCheckBox
                    label="적하목록 신고 여부"
                    checkedValue={Boolean(v.opCheckPoint.didANcheckedAt)}
                    onChange={() => {
                      handleTrelloCardOpCheckPoint({
                        shipmentId: v.id,
                        isChecked: Boolean(v.opCheckPoint.didANcheckedAt),
                        key: "didANcheckedAt",
                        isImport: true,
                      });
                    }}
                  />
                </Box>
              ),
              handleTrelloCardClick: () => {
                handleTrelloCardClick(v);
              },
            };
          }),
      },
      {
        lineKey: "3",
        lineTitle: "출항 준비 중",
        cards: trelloListData
          .filter(
            (v: TrelloBidList) =>
              v.projectStatus === "scheduling" ||
              v.projectStatus === "containerCarryOut" ||
              v.projectStatus === "containerPickup" ||
              v.projectStatus === "gateIn" ||
              v.projectStatus === "loaded"
          )
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullETD"))
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),

              freightType: `${v.freightType}/${v.incoterms}`,
              projectStatus: v.projectStatus,
              badge: {
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
                isETDExpiration: checkIfETDExpiresWhenScheduling(v),
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  <CustomStatusChip status={v.management.customsStatus} />

                  <>
                    <TrelloCardCheckBox
                      label="BL(AWB) 입력"
                      checkedValue={checkIsBlNumberHasRegistered(v)}
                    />

                    <TrelloCardCheckBox
                      label="BL(AWB) 업로드"
                      checkedValue={v.attachmentFlag}
                    />

                    {v.freightType === "FCL" && (
                      <TrelloCardCheckBox
                        label="공 컨테이너 반출"
                        checkedValue={
                          v.projectStatus === "containerCarryOut" ||
                          v.projectStatus === "containerPickup" ||
                          v.projectStatus === "gateIn" ||
                          v.projectStatus === "loaded" ||
                          v.projectStatus === "moving"
                        }
                      />
                    )}

                    {v.freightType === "FCL" && (
                      <TrelloCardCheckBox
                        label="현지 컨테이너 픽업완료"
                        checkedValue={
                          v.projectStatus === "containerPickup" ||
                          v.projectStatus === "gateIn" ||
                          v.projectStatus === "loaded" ||
                          v.projectStatus === "moving"
                        }
                      />
                    )}

                    {/* TODO: 운송 리뉴얼에서 빠진 기능 추후에 추가 예정 */}
                    {v.freightType !== "FCL" && (
                      <TrelloCardCheckBox label="현지 CFS 반입완료" />
                    )}

                    <TrelloCardCheckBox
                      label="현지 터미널 반입완료"
                      checkedValue={
                        v.projectStatus === "gateIn" ||
                        v.projectStatus === "loaded" ||
                        v.projectStatus === "moving"
                      }
                    />

                    <TrelloCardCheckBox
                      label="선적 완료"
                      checkedValue={
                        v.projectStatus === "loaded" ||
                        v.projectStatus === "moving"
                      }
                    />

                    <TrelloCardCheckBox
                      label="출항 완료"
                      checkedValue={v.projectStatus === "moving"}
                    />

                    {APP_NAME === "shipda-admin" && (
                      <TrelloCardCheckBox
                        label="적하목록 신고 여부"
                        checkedValue={Boolean(v.opCheckPoint.didANcheckedAt)}
                        onChange={() => {
                          handleTrelloCardOpCheckPoint({
                            shipmentId: v.id,
                            isChecked: Boolean(v.opCheckPoint.didANcheckedAt),
                            key: "didANcheckedAt",
                            isImport: true,
                          });
                        }}
                      />
                    )}
                  </>
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "4",
        lineTitle: "출항 완료",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "moving")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullETA"))
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),

              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isETAExpiration:
                  APP_NAME === "shipda-admin" && checkETAExpires(v),
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
                isDeadlineFreeTime: checkDeadlineFreeTime(v),
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  <CustomStatusChip status={v.management.customsStatus} />

                  <TrelloCardCheckBox
                    label="적하목록 신고 여부"
                    checkedValue={Boolean(v.opCheckPoint.didANcheckedAt)}
                    onChange={() => {
                      handleTrelloCardOpCheckPoint({
                        shipmentId: v.id,
                        isChecked: Boolean(v.opCheckPoint.didANcheckedAt),
                        key: "didANcheckedAt",
                        isImport: true,
                      });
                    }}
                  />

                  {v.freightType !== "AIR" &&
                    v.opCheckPoint.typeBL === "original" && (
                      <TrelloCardCheckBox
                        label="OBL 수취여부"
                        checkedValue={Boolean(
                          v.opCheckPoint.originalBLCheckedAt
                        )}
                        onChange={() => {
                          handleTrelloCardOpCheckPoint({
                            shipmentId: v.id,
                            isChecked: Boolean(
                              v.opCheckPoint.originalBLCheckedAt
                            ),
                            key: "originalBLCheckedAt",
                            isImport: true,
                          });
                        }}
                      />
                    )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "5",
        lineTitle: "입항완료 및 통관 준비",
        cards: trelloListData
          .filter(
            (v: TrelloBidList) =>
              v.projectStatus === "portEntryAndPrepareCustoms"
          )
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullATA"))
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
                isDeadlineFreeTime: checkDeadlineFreeTime(v),
                isInvoiceIssued: Boolean(v.closingInvoice) === true,
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  <CustomStatusChip status={v.management.customsStatus} />

                  <TrelloCardCheckBox
                    label="통관 필수서류 구비완료"
                    checkedValue={v.attachmentFlag}
                  />

                  <TrelloCardCheckBox
                    label="관세사 지정하기"
                    checkedValue={checkIsCustomsPartnerHasRegistered(v)}
                  />

                  <TrelloCardCheckBox
                    label="통관 서류 및 거래명세서 발송(관세사)"
                    checkedValue={v.management.detailStatus === "sendedCustoms"}
                  />

                  {/** SG쉽다 의뢰의 경우 유니패스를 사용하지 않아 거래명세서 발송으로 대체 */}
                  {v.region === "KR" ? (
                    <TrelloCardCheckBox label="수입결재통보" />
                  ) : (
                    <TrelloCardCheckBox
                      label="거래명세서 발송(고객)"
                      checkedValue={
                        v.management.customsPaymentFlag ||
                        v.invoices[0]?.isSended
                      }
                    />
                  )}

                  <TrelloCardCheckBox
                    label="적하목록 신고 여부"
                    checkedValue={Boolean(v.opCheckPoint.didANcheckedAt)}
                    onChange={() => {
                      handleTrelloCardOpCheckPoint({
                        shipmentId: v.id,
                        isChecked: Boolean(v.opCheckPoint.didANcheckedAt),
                        key: "didANcheckedAt",
                        isImport: true,
                      });
                    }}
                  />

                  {v.freightType !== "AIR" &&
                    v.opCheckPoint.typeBL === "original" && (
                      <TrelloCardCheckBox
                        label="OBL 수취여부"
                        checkedValue={Boolean(
                          v.opCheckPoint.originalBLCheckedAt
                        )}
                        onChange={() => {
                          handleTrelloCardOpCheckPoint({
                            shipmentId: v.id,
                            isChecked: Boolean(
                              v.opCheckPoint.originalBLCheckedAt
                            ),
                            key: "originalBLCheckedAt",
                            isImport: true,
                          });
                        }}
                      />
                    )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "6",
        lineTitle: "정산 요청",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "payment")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullATA"))
          .map((v: TrelloBidList) => {
            const customsAccountPayable = v.accountPayables.filter(
              ({ domain }) => domain === "customs"
            )[0];

            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isDeadlineFreeTime: checkDeadlineFreeTime(v),
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
                isInvoiceIssued: Boolean(v.closingInvoice) === true,
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  <CustomStatusChip status={v.management.customsStatus} />

                  {customsAccountPayable?.customsReviewRequestedFlag && (
                    <Box
                      sx={{
                        background: "#50C878",
                        width: "100px",
                        textAlign: "center",
                        border: "1px solid white",
                      }}
                    >
                      <p className="review-request">서류검토 요청</p>
                    </Box>
                  )}

                  {/** SG쉽다 의뢰에서 거래명세서 발송은 입항완료 및 통관 준비 단계에서 필요한 조건 */}
                  {v.region === "KR" && (
                    <TrelloCardCheckBox
                      label="거래명세서 발송(고객)"
                      checkedValue={
                        v.management.customsPaymentFlag ||
                        v.invoices[0]?.isSended
                      }
                    />
                  )}

                  <TrelloCardCheckBox label="수입신고수리" />

                  <TrelloCardCheckBox
                    label="적하목록 신고 여부"
                    checkedValue={Boolean(v.opCheckPoint.didANcheckedAt)}
                    onChange={() => {
                      handleTrelloCardOpCheckPoint({
                        shipmentId: v.id,
                        isChecked: Boolean(v.opCheckPoint.didANcheckedAt),
                        key: "didANcheckedAt",
                        isImport: true,
                      });
                    }}
                  />

                  {v.freightType !== "AIR" &&
                    v.opCheckPoint.typeBL === "original" && (
                      <TrelloCardCheckBox
                        label="OBL 수취여부"
                        checkedValue={Boolean(
                          v.opCheckPoint.originalBLCheckedAt
                        )}
                        onChange={() => {
                          handleTrelloCardOpCheckPoint({
                            shipmentId: v.id,
                            isChecked: Boolean(
                              v.opCheckPoint.originalBLCheckedAt
                            ),
                            key: "originalBLCheckedAt",
                            isImport: true,
                          });
                        }}
                      />
                    )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "7",
        lineTitle: "통관 완료",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "completeCustoms")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullATA"))
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),

              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isCompletedCustoms: checkIsInlandFarePaymentRequest(v),
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
                isDeadlineFreeTime: checkDeadlineFreeTime(v),
                isInvoiceIssued: Boolean(v.closingInvoice) === true,
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  {v.endAddress && (
                    <TrelloCardCheckBox
                      label="기사 정보 입력"
                      checkedValue={checkIsInlandPartnerHasRegistered(v)}
                    />
                  )}

                  {v.containCustoms && (
                    <TrelloCardCheckBox
                      label="수입신고필증/계산서 업로드"
                      checkedValue={v.attachmentFlag}
                    />
                  )}

                  <TrelloCardCheckBox
                    label="반출 신고"
                    checkedValue={v.management.confirmFlag}
                  />

                  <TrelloCardCheckBox
                    label="적하목록 신고 여부"
                    checkedValue={Boolean(v.opCheckPoint.didANcheckedAt)}
                    onChange={() => {
                      handleTrelloCardOpCheckPoint({
                        shipmentId: v.id,
                        isChecked: Boolean(v.opCheckPoint.didANcheckedAt),
                        key: "didANcheckedAt",
                        isImport: true,
                      });
                    }}
                  />

                  {v.freightType !== "AIR" &&
                    v.opCheckPoint.typeBL === "original" && (
                      <TrelloCardCheckBox
                        label="OBL 수취여부"
                        checkedValue={Boolean(
                          v.opCheckPoint.originalBLCheckedAt
                        )}
                        onChange={() => {
                          handleTrelloCardOpCheckPoint({
                            shipmentId: v.id,
                            isChecked: Boolean(
                              v.opCheckPoint.originalBLCheckedAt
                            ),
                            key: "originalBLCheckedAt",
                            isImport: true,
                          });
                        }}
                      />
                    )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "8",
        lineTitle: "내륙 운송 중",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "delivering")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullATA"))
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isDeadlineFreeTime: checkDeadlineFreeTime(v),
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
                isInvoiceIssued: Boolean(v.closingInvoice) === true,
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  <TrelloCardCheckBox
                    label="적하목록 신고 여부"
                    checkedValue={Boolean(v.opCheckPoint.didANcheckedAt)}
                    onChange={() => {
                      handleTrelloCardOpCheckPoint({
                        shipmentId: v.id,
                        isChecked: Boolean(v.opCheckPoint.didANcheckedAt),
                        key: "didANcheckedAt",
                        isImport: true,
                      });
                    }}
                  />

                  {v.freightType !== "AIR" &&
                    v.opCheckPoint.typeBL === "original" && (
                      <TrelloCardCheckBox
                        label="OBL 수취여부"
                        checkedValue={Boolean(
                          v.opCheckPoint.originalBLCheckedAt
                        )}
                        onChange={() => {
                          handleTrelloCardOpCheckPoint({
                            shipmentId: v.id,
                            isChecked: Boolean(
                              v.opCheckPoint.originalBLCheckedAt
                            ),
                            key: "originalBLCheckedAt",
                            isImport: true,
                          });
                        }}
                      />
                    )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "9",
        lineTitle: "종료",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "finished")
          .filter(
            filterByTrelloCardIssue(sessionStorageTrelloIssueFilter || "none")
          )
          .sort(sortByShipmentScheduleDateDesc("fullATA"))
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              badge: {
                isDeadlineFreeTime: checkDeadlineFreeTime(v),
                isStatusChanged: checkStatusUpdate(
                  v.management.externalApiUpdatedAt
                ),
                isScheduleChanged: checkScheduleUpdate(
                  v.management.scheduleUpdatedItems
                ),
                isInvoiceIssued: Boolean(v.closingInvoice) === true,
              },
              description: (
                <Box>
                  <TrelloCardCommonBody trelloListData={v} />

                  <TrelloCardCheckBox
                    label="정산 하기"
                    checkedValue={checkIsInlandPartnerHasRegistered(v)}
                  />

                  <TrelloCardCheckBox
                    label="적하목록 신고 여부"
                    checkedValue={Boolean(v.opCheckPoint.didANcheckedAt)}
                    onChange={() => {
                      handleTrelloCardOpCheckPoint({
                        shipmentId: v.id,
                        isChecked: Boolean(v.opCheckPoint.didANcheckedAt),
                        key: "didANcheckedAt",
                        isFinished: true,
                        isImport: true,
                      });
                    }}
                  />

                  {v.freightType !== "AIR" &&
                    v.opCheckPoint.typeBL === "original" && (
                      <TrelloCardCheckBox
                        label="OBL 수취여부"
                        checkedValue={Boolean(
                          v.opCheckPoint.originalBLCheckedAt
                        )}
                        onChange={() => {
                          handleTrelloCardOpCheckPoint({
                            shipmentId: v.id,
                            isChecked: Boolean(
                              v.opCheckPoint.originalBLCheckedAt
                            ),
                            key: "originalBLCheckedAt",
                            isImport: true,
                          });
                        }}
                      />
                    )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
    ];
  }, [
    trelloListData,
    sessionStorageTrelloIssueFilter,
    handleTrelloCardClick,
    handleTrelloCardOpCheckPoint,
  ]);

  return { importCardListData };
}
