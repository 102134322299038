import { useMemo } from "react";

import { GET_TRELLO_ATTACHMENTS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";
import { APP_NAME } from "@sellernote/_shared/src/constants";
import { TrelloUploadDocumentTableData } from "@sellernote/_shared/src/types/forwarding/trello";

export default function useTrelloUploadDocumentsTableData({
  isImport,
  attachments,
}: {
  isImport: boolean;
  attachments: GET_TRELLO_ATTACHMENTS_RES | undefined;
}) {
  const trelloUploadDocumentTableData = useMemo(() => {
    const uploadDocumentTableData: TrelloUploadDocumentTableData[] = [
      {
        domain: "CI_PL",
        attachments: [],
      },
      {
        domain: "BL",
        attachments: [],
      },
      ...(isImport ? [] : [{ domain: "HBL" as const, attachments: [] }]),
      {
        domain: "CO",
        attachments: [],
      },
      {
        domain: "brn",
        attachments: [],
      },
      { domain: "certificate", attachments: [] },
      { domain: "etc", attachments: [] },
      // 수입세금계산서는 수출에서는 사용하지 않음
      ...(isImport ? [{ domain: "importTax" as const, attachments: [] }] : []),
      // 수입신고필증은 수출일 때 수출신고필증으로 변경
      {
        domain: isImport ? "importDeclaration" : "exportDeclaration",
        attachments: [],
      },
      {
        domain: "arrivalNotice",
        attachments: [],
      },
      { domain: "houseDo", attachments: [] },
      {
        domain: "releaseRequest",
        attachments: [],
      },
      {
        domain: "parcelRequest",
        attachments: [],
      },
      {
        domain: "adminTemp",
        attachments: [],
      },
    ];

    if (!attachments) {
      return;
    }

    return attachments.reduce((acc, cur) => {
      const targetDomain = (() => {
        // 수출의뢰에서 domain이 BL이며 파일명이 _SHIPDA_를 포함한 경우 HBL로 판단한다.
        if (!isImport && cur.domain === "BL" && cur.name.includes("_SHIPDA_")) {
          return "HBL";
        }

        // 수출의뢰에서 domain이 BL이며 경우 파일명이 _SHIPDA_를 포함하지 않는 경우 BL로 판단한다.
        if (
          !isImport &&
          cur.domain === "BL" &&
          !cur.name.includes("_SHIPDA_")
        ) {
          return "BL";
        }

        return cur.domain;
      })();

      // 대상 도메인에 해당하는 uploadDocumentTableData의 attachments 객체를 찾는다.
      const targetCategory = acc.find((item) => item.domain === targetDomain);

      if (targetCategory) {
        targetCategory.attachments = [...targetCategory.attachments, cur];
      }

      return acc;
    }, uploadDocumentTableData);
  }, [attachments, isImport])?.filter((v) => {
    // 파트너, 수출에서 공통으로 숨기는 파일 필드
    const isHiddenFileDomain =
      v.domain === "parcelRequest" ||
      v.domain === "releaseRequest" ||
      v.domain === "houseDo";

    // 수출에서는 AN(arrivalNotice)를 숨김
    if (!isImport) {
      if (v.domain === "arrivalNotice" || isHiddenFileDomain) {
        return false;
      }
    }

    // 파트너 어드민에서는 기타보관용(adminTemp)를 숨김
    if (APP_NAME === "partner-admin") {
      if (isHiddenFileDomain || v.domain === "adminTemp") {
        return false;
      }
    }
    return true;
  });

  return { trelloUploadDocumentTableData };
}
