import { createTheme } from "@mui/material";

/**
 * 쉽다 Admin, 파트너 Admin에서 사용되는 Mui Component overriding style 정의
 * components 객체 내부 순서는 'Mui' Prefix를 제외한 컴포넌트 명을 알파벳 순서로 정렬
 *
 * @requires className="sads"
 *   - overriding style 적용을 위한 className
 *     - (e.g. <MuiComponent className="sads">)
 * @requires !important
 *   - 아래 정의된 style을 다시 override 해야할 떄 사용
 *     - (e.g. <MuiComponent className="sads" sx={{ color: "red !important" }}>
 */
const theme = createTheme({
  components: {
    MuiDivider: {
      styleOverrides: {
        root: { "&.sads": {} },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: { "&.sads": {} },
      },
    },

    MuiTooltip: {
      defaultProps: {
        arrow: true,
        /**
         * 툴팁 내부 padding, 기본 fontSize 스타일링
         * FIXME: 스타일에 사용된 값 상수로 처리
         */
        componentsProps: {
          tooltip: {
            sx: { p: 1, fontSize: 14 },
          },
        },
      },
      styleOverrides: {
        tooltip: { "&.sads": {} },
      },
    },
  },
});

export default theme;
