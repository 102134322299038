import { useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";

import useSnackbar from "../../../../../../../hooks/useSnackbar";

export default function useEndAddressFormLogic({
  trelloDetail,
  bidAccountPayableId,
  setOpensEndAddressFormModal,
}: {
  trelloDetail: TrelloBidDetail;
  bidAccountPayableId: number | undefined;
  setOpensEndAddressFormModal: (value: boolean) => void;
}) {
  const history = useHistory();

  const queryClient = useQueryClient();

  const { handleSnackbarOpen } = useSnackbar();

  const hasDestinationButNoEndAddressDetail = (() => {
    const { zoneId, endAddress, endAddressDetail, serviceType } = trelloDetail;

    if (
      serviceType === "consolidation" &&
      zoneId &&
      endAddress &&
      endAddressDetail === null
    )
      return true;

    return false;
  })();

  const [zoneName, setZoneName] = useState(
    hasDestinationButNoEndAddressDetail
      ? trelloDetail.zone?.name || ""
      : trelloDetail.endAddress || ""
  );
  const [zoneId, setZoneId] = useState(trelloDetail.zoneId || 0);
  const [endAddressDetail, setEndAddressDetail] = useState(
    hasDestinationButNoEndAddressDetail
      ? trelloDetail.endAddress || ""
      : trelloDetail.endAddressDetail || ""
  );

  const {
    mutate: registerInland,
    ResponseHandler: ResponseHandlerOfRegisterInland,
  } = TRELLO_BID_QUERY.useRegisterInland(trelloDetail.id, {
    handleConfirmSuccess: (initQuery) => {
      initQuery();
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
      // 도착지 등록 시 거래명세서 페이지에서 관련 항목을 추가해야한다.
      history.push(
        `/tradingStatement/invoice/${trelloDetail.id}/${
          trelloDetail.isImport ? "import" : "export"
        }`
      );
    },

    customizeMessage: () => ({
      title: "내륙운송을 수정했습니다.",
    }),
  });

  const {
    mutate: cancelInlandTransport,
    ResponseHandler: ResponseHandlerOfUseCancelInlandTransport,
  } = TRELLO_BID_QUERY.useCancelInlandTransport(trelloDetail.id, {
    handleConfirmSuccess: (initQuery) => {
      initQuery();
      setOpensEndAddressFormModal(false);
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
    },

    customizeMessage: () => ({
      title: "내륙 운송을 취소했습니다.",
    }),
  });

  const {
    mutate: editInlandType,
    ResponseHandler: ResponseHandlerOfEditInlandType,
  } = TRELLO_BID_QUERY.useEditInlandType({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setOpensEndAddressFormModal(false);
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
      },

      customizeMessage: () => ({
        title: "내륙운송을 수정했습니다.",
      }),
    },
    bidId: trelloDetail.id,
  });

  const handleInlandTransportChange = () => {
    // 내륙주소, 상세 주소가 없이 요청했을 때는 내륙운송을 취소 요청을한다.
    if (!zoneName && !endAddressDetail && bidAccountPayableId) {
      handleSnackbarOpen("주소와 상세주소를 입력해주세요.", "warning");
      return;
    }

    if (!zoneId) {
      handleSnackbarOpen("주소지를 입력해주세요.", "warning");
      return;
    }

    editInlandType({
      bidAccountPayableId,
      detailFlag: false,
      zoneId,
      endAddress: zoneName,
      endAddressDetail: endAddressDetail,
    });
  };

  const handleInlandTypeRegisterAndMoveToTradingStatement = () => {
    if (!zoneId) {
      handleSnackbarOpen("주소지를 입력해주세요.", "warning");
      return;
    }
    registerInland({
      bidAccountPayableId,
      inlandFlag: true,
      zoneId,
      endAddress: zoneName,
      endAddressDetail: endAddressDetail,
    });
  };

  const handleInlandTypeCancel = () => {
    if (!trelloDetail.zone && !trelloDetail.endAddress) {
      handleSnackbarOpen("도착지 정보가 등록되지 않았습니다.", "warning");
      return;
    }
    if (!bidAccountPayableId) {
      handleSnackbarOpen("내륙 파트너 정보가 없습니다.", "warning");
      return;
    }

    cancelInlandTransport({
      bidAccountPayableId,
      inlandFlag: false,
    });
  };

  return {
    zoneName,
    setZoneName,
    setZoneId,
    setEndAddressDetail,
    endAddressDetail,
    handleInlandTypeRegisterAndMoveToTradingStatement,
    handleInlandTransportChange,
    ResponseHandlerOfRegisterInland,
    ResponseHandlerOfUseCancelInlandTransport,
    ResponseHandlerOfEditInlandType,
    handleInlandTypeCancel,
  };
}
