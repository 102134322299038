import { Box, TextField, Typography } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";

import {
  CONTAINER_STYLE,
  CUSTOM_TEXT_FIELD_STYLE,
  TITLE_STYLE,
} from "../styles";
import { highlightField } from "../utils";

export default function FirstCategoryForm({
  form,
  onFormChange,
  isEditMode,
  isImport,
}: {
  form: CargoManifestInfoProps;
  onFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEditMode: boolean;
  isImport: boolean;
}) {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      justifyContent={"center"}
      width={"50%"}
    >
      <Box sx={{ ...CONTAINER_STYLE, ...highlightField(form, "mBL") }}>
        <Typography sx={TITLE_STYLE}>MBL</Typography>

        <TextField
          value={form.mBL}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="mBL"
          onChange={onFormChange}
          disabled={!isEditMode}
          fullWidth
        />
      </Box>

      {isImport && (
        <Box sx={{ ...CONTAINER_STYLE, ...highlightField(form, "MSN") }}>
          <Typography sx={TITLE_STYLE}>MSN</Typography>

          <TextField
            value={form.importation.MSN}
            variant="outlined"
            size="small"
            sx={CUSTOM_TEXT_FIELD_STYLE}
            name="MSN"
            onChange={onFormChange}
            disabled={!isEditMode}
            fullWidth
          />
        </Box>
      )}

      <Box sx={{ ...CONTAINER_STYLE, ...highlightField(form, "hBL") }}>
        <Typography sx={TITLE_STYLE}>HBL</Typography>

        <TextField
          value={form.hBL}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="hBL"
          onChange={onFormChange}
          disabled={!isEditMode}
          fullWidth
        />
      </Box>

      <Box
        sx={{
          ...CONTAINER_STYLE,
          ...highlightField(form, "carrierCode"),
        }}
      >
        <Typography sx={TITLE_STYLE}>선사부호</Typography>

        <TextField
          value={form.carrierCode}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="carrierCode"
          onChange={onFormChange}
          disabled={!isEditMode}
          fullWidth
        />
      </Box>

      <Box
        sx={{
          ...CONTAINER_STYLE,
          ...highlightField(form, "classification"),
        }}
      >
        <Typography sx={TITLE_STYLE}>화물구분</Typography>

        <TextField
          value={form.classification}
          name={"classification"}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          onChange={onFormChange}
          disabled={!isEditMode}
          fullWidth
        />
      </Box>

      <Box sx={CONTAINER_STYLE}>
        <Typography sx={TITLE_STYLE}>포장개수</Typography>

        <TextField
          value={form.packageCount}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="packageCount"
          onChange={onFormChange}
          disabled={!isEditMode}
          fullWidth
        />
      </Box>

      <Box
        sx={{
          ...CONTAINER_STYLE,
          ...highlightField(form, "packageSign"),
        }}
      >
        <Typography sx={TITLE_STYLE}>포장부호</Typography>

        <TextField
          value={form.packageSign}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="packageSign"
          onChange={onFormChange}
          disabled={!isEditMode}
          fullWidth
        />
      </Box>

      <Box sx={CONTAINER_STYLE}>
        <Typography sx={TITLE_STYLE}>총 중량(KG)</Typography>

        <TextField
          value={form.totalWeight}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="totalWeight"
          onChange={onFormChange}
          disabled={!isEditMode}
          fullWidth
        />
      </Box>

      <Box sx={CONTAINER_STYLE}>
        <Typography sx={TITLE_STYLE}>총 용적(CBM)</Typography>

        <TextField
          value={form.totalCBM}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="totalCBM"
          onChange={onFormChange}
          disabled={!isEditMode}
          fullWidth
        />
      </Box>

      <Box sx={{ ...CONTAINER_STYLE, ...highlightField(form, "itemName") }}>
        <Typography sx={TITLE_STYLE}>품명</Typography>

        <TextField
          value={form.itemName}
          multiline
          rows={3}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="itemName"
          onChange={onFormChange}
          disabled={!isEditMode}
          fullWidth
        />
      </Box>

      {isImport ? (
        <>
          <Box sx={{ ...CONTAINER_STYLE, ...highlightField(form, "spclCode") }}>
            <Typography sx={TITLE_STYLE}>특수화물 코드</Typography>

            <TextField
              value={form.importation.spclCode}
              variant="outlined"
              size="small"
              sx={CUSTOM_TEXT_FIELD_STYLE}
              name="spclCode"
              onChange={onFormChange}
              disabled={!isEditMode}
              fullWidth
            />
          </Box>

          <Box
            sx={{
              ...CONTAINER_STYLE,
              ...highlightField(form, "assignCode"),
            }}
          >
            <Typography sx={TITLE_STYLE}>배정구분 코드</Typography>

            <TextField
              value={form.importation.assignCode}
              variant="outlined"
              size="small"
              sx={CUSTOM_TEXT_FIELD_STYLE}
              name="assignCode"
              onChange={onFormChange}
              disabled={!isEditMode}
              fullWidth
            />
          </Box>

          <Box sx={CONTAINER_STYLE}>
            <Typography sx={TITLE_STYLE}>배정장소</Typography>

            <TextField
              value={form.importation.terminalCode}
              variant="outlined"
              size="small"
              sx={CUSTOM_TEXT_FIELD_STYLE}
              name="terminalCode"
              onChange={onFormChange}
              disabled={!isEditMode}
              fullWidth
            />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              ...CONTAINER_STYLE,
              ...highlightField(form, "declarationNumber"),
            }}
          >
            <Typography sx={TITLE_STYLE}>수출신고번호</Typography>

            <TextField
              value={form.exportation.declarationNumber}
              variant="outlined"
              size="small"
              sx={CUSTOM_TEXT_FIELD_STYLE}
              name="declarationNumber"
              onChange={onFormChange}
              disabled={!isEditMode}
              fullWidth
            />
          </Box>

          <Box
            sx={{
              ...CONTAINER_STYLE,
              ...highlightField(form, "partialShipment"),
            }}
          >
            <Typography sx={TITLE_STYLE}>분할선적여부</Typography>

            <TextField
              value={form.exportation.partialShipment}
              variant="outlined"
              size="small"
              sx={CUSTOM_TEXT_FIELD_STYLE}
              name="partialShipment"
              onChange={onFormChange}
              disabled={!isEditMode}
              fullWidth
            />
          </Box>
        </>
      )}
    </Box>
  );
}
