import { Box, TextField } from "@mui/material";

import DatePicker from "../../../../../../../../components/DatePicker";

export default function WithdrawalInvoice({
  withdrawalInvoiceDate,
  onWithdrawalInvoiceDateChange,
  withdrawalInvoiceId,
  onWithdrawalInvoiceIdChange,
}: {
  withdrawalInvoiceDate: string | null;
  onWithdrawalInvoiceDateChange: (date: string | null) => void;
  withdrawalInvoiceId: string;
  onWithdrawalInvoiceIdChange: (id: string) => void;
}) {
  return (
    <Box display={"flex"} gap={1}>
      <DatePicker
        label={"INV. Date"}
        when="end"
        value={withdrawalInvoiceDate}
        setDate={onWithdrawalInvoiceDateChange}
        width={180}
      />

      <TextField
        sx={{ width: 200 }}
        label={"INV No."}
        value={withdrawalInvoiceId}
        size={"small"}
        onChange={(e) => {
          onWithdrawalInvoiceIdChange(e.target.value);
        }}
      />
    </Box>
  );
}
