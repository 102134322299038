import { FileDownload } from "@mui/icons-material";
import { Button } from "@mui/material";
import fileDownload from "js-file-download";

import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { sendRequest } from "@sellernote/_shared/src/services/request";
import { AttachmentForDownload } from "@sellernote/_shared/src/types/forwarding/trello";

import useSnackbar from "../../../../hooks/useSnackbar";

function MsdsDownload({
  bidId,
  attachments,
}: {
  bidId: number;
  attachments: AttachmentForDownload[];
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const { mutate: getFileDownloadUrl } =
    ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  return (
    <>
      <Button
        startIcon={<FileDownload />}
        onClick={async () => {
          // 파일이 한개만 있을 경우
          if (attachments.length === 1) {
            getFileDownloadUrl(
              {
                pathParams: {
                  key: attachments[0].key,
                },
              },
              {
                onSuccess: ({ data }) => {
                  window.location.href = data.url;
                },

                onError: () => {
                  handleSnackbarOpen("요청에 실패했습니다.", "error");
                },
              }
            );
            return;
          }

          try {
            await sendRequest({
              method: "post",
              path: "/attachments/compress",
              apiType: "ShipdaAdminDefaultNew",
              responseType: "blob",
              data: {
                bidId,
                domain: "MSDS",
              },
            }).then((response) => {
              const blobData = new Blob([response.data]);
              fileDownload(blobData, "MSDS 파일.zip");
            });
          } catch (error) {
            handleSnackbarOpen("MSDS 파일 다운로드에 실패했습니다.", "error");
          }
        }}
      >
        MSDS 다운로드
      </Button>
    </>
  );
}

export default MsdsDownload;
