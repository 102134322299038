import { useState } from "react";

import { PreviewChangeProps } from "../types";
import { PreviewType } from "../types";
import { PreviewZoomProps } from "../types";

export default function useHandlePreviewContent({
  isImport,
}: {
  isImport: boolean;
}) {
  const [imageWidth, setImageWidth] = useState(100);
  const [pdfScale, setPdfScale] = useState(1.4);
  const [tab, setTab] = useState<PreviewType>(
    isImport ? "arrivalNotice" : "exportDeclaration"
  );
  const [previewIndex, setPreviewIndex] = useState(0);

  const handleImageWidthChange = (value: PreviewZoomProps) => {
    if (value === "zoomOut") {
      setImageWidth((prev) => prev - 10);
      return;
    }

    setImageWidth((prev) => prev + 10);
  };

  const handlePdfScaleChange = (value: PreviewZoomProps) => {
    if (value === "zoomOut") {
      setPdfScale((prev) => prev - 0.1);
      return;
    }

    setPdfScale((prev) => prev + 0.1);
  };

  const handlePreviewIndexChange = (v: PreviewChangeProps) => {
    if (v === "next") {
      setPreviewIndex((prev) => prev + 1);
      return;
    }

    setPreviewIndex((prev) => prev - 1);
  };

  const handleTabChange = (v: PreviewType) => {
    setPreviewIndex(0);
    setTab(v);
  };

  return {
    imageWidth,
    pdfScale,
    tab,
    previewIndex,
    handleImageWidthChange,
    handlePdfScaleChange,
    handlePreviewIndexChange,
    handleTabChange,
  };
}
