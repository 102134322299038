import { Box, TextField, Typography } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";

import {
  CONTAINER_STYLE,
  CUSTOM_TEXT_FIELD_STYLE,
  TITLE_STYLE,
} from "../styles";

import DatePicker from "../../../../../../../../../../../components/DatePicker";

export default function SecondCategoryForm({
  form,
  onFormChange,
  onEstimatedTimeChange,
  isEditMode,
  isImport,
}: {
  form: CargoManifestInfoProps;
  onFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEstimatedTimeChange: (value: string | null, isImport: boolean) => void;
  isEditMode: boolean;
  isImport: boolean;
}) {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1.5}>
      <Box sx={CONTAINER_STYLE}>
        <Typography sx={TITLE_STYLE}>MRN</Typography>

        <TextField
          value={form.MRN}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="MRN"
          onChange={onFormChange}
          disabled={!isEditMode}
          fullWidth
        />
      </Box>

      <Box sx={CONTAINER_STYLE}>
        <Typography sx={TITLE_STYLE}>{isImport ? "ETA" : "ETD"}</Typography>

        <DatePicker
          value={isImport ? form.importation.ETA : form.exportation.ETD}
          setDate={(value) => onEstimatedTimeChange(value, isImport)}
          when={"start"}
          disabled={!isEditMode}
          fullWidth
        />
      </Box>

      <Box sx={CONTAINER_STYLE}>
        <Typography sx={TITLE_STYLE}>선박호출부호</Typography>

        <TextField
          value={form.callSign}
          variant="outlined"
          size="small"
          sx={CUSTOM_TEXT_FIELD_STYLE}
          name="callSign"
          onChange={onFormChange}
          disabled={!isEditMode}
          fullWidth
        />
      </Box>
    </Box>
  );
}
