import { Box, TextField, Typography } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";

import { ContainerFormChangeProps } from "../../../types";
import {
  CONTAINER_STYLE,
  CUSTOM_TEXT_FIELD_STYLE,
  TITLE_STYLE,
} from "../styles";
import useContainerBoxHorizontalScroll from "./hooks";
import highlightSealNo from "./utils";

export default function ContainerInfoForm({
  form,
  onContainerFormChange,
  isEditMode,
  isImport,
}: {
  form: CargoManifestInfoProps;
  onContainerFormChange: ({
    index,
    key,
    value,
  }: ContainerFormChangeProps) => void;
  isEditMode: boolean;
  isImport: boolean;
}) {
  const { containerRef } = useContainerBoxHorizontalScroll();

  const containerNumber = form.containers?.length ?? 0;

  return (
    <>
      <Typography component="span" fontWeight={"bold"} mt={2}>
        컨테이너 정보 (총 {containerNumber}개)
      </Typography>

      <Box
        display={"flex"}
        overflow={"auto"}
        ref={containerRef}
        sx={{ "&::-webkit-scrollbar": { height: 10 } }}
        width={"100%"}
      >
        {form.containers?.map((container, index) => {
          const hasContainerPackageCount = "containerPackageCount" in container;

          return (
            <Box
              key={container.containerNumber}
              mb={containerNumber > 3 ? 2 : 0}
              display={"flex"}
              mr={5}
            >
              <Box display="flex" flexDirection={"column"} gap={1}>
                <Box sx={CONTAINER_STYLE}>
                  <Typography sx={TITLE_STYLE}>CNTR NO.</Typography>

                  <Box display={"flex"} alignItems={"center"} height={27}>
                    {container.containerNumber}
                  </Box>
                </Box>

                <Box sx={CONTAINER_STYLE}>
                  <Typography sx={TITLE_STYLE}>Size/Type</Typography>

                  <TextField
                    value={container.containerType}
                    variant="outlined"
                    size="small"
                    sx={CUSTOM_TEXT_FIELD_STYLE}
                    name="sealNo"
                    onChange={(e) =>
                      onContainerFormChange({
                        index,
                        key: "containerType",
                        value: e.target.value,
                      })
                    }
                    disabled={!isEditMode}
                  />
                </Box>

                <Box
                  sx={{
                    ...CONTAINER_STYLE,
                    ...highlightSealNo(container.sealNo),
                  }}
                >
                  <Typography sx={TITLE_STYLE}>Seal No.</Typography>

                  <TextField
                    value={container.sealNo}
                    variant="outlined"
                    size="small"
                    sx={CUSTOM_TEXT_FIELD_STYLE}
                    name="sealNo"
                    onChange={(e) =>
                      onContainerFormChange({
                        index,
                        key: "sealNo",
                        value: e.target.value,
                      })
                    }
                    disabled={!isEditMode}
                  />
                </Box>

                {isImport && (
                  <Box sx={CONTAINER_STYLE}>
                    <Typography sx={TITLE_STYLE}>포장개수</Typography>

                    <TextField
                      value={undefined}
                      defaultValue={
                        hasContainerPackageCount
                          ? container.containerPackageCount
                          : undefined
                      }
                      variant="outlined"
                      size="small"
                      sx={CUSTOM_TEXT_FIELD_STYLE}
                      name="packageCount"
                      type="number"
                      onChange={(e) => {
                        onContainerFormChange({
                          index,
                          key: "containerPackageCount",
                          value: Number(e.target.value),
                        });
                      }}
                      disabled={!isEditMode}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
