import { GET_ADMIN_SETTLEMENT_LIST_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminSettlement";
import useHistoryState from "@sellernote/_shared/src/hooks/common/useHistoryState";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_SETTLEMENT_QUERY";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";

import Layout from "../../containers/Layout";

import SettlementTable from "./SettlementTable";
import SettlementTableFilter from "./SettlementTableFilter";

function SettlementList() {
  const [fetchSettlementListParams, setFetchSettlementListParams] =
    useHistoryState<GET_ADMIN_SETTLEMENT_LIST_REQ>(
      "fetchSettlementListParams",
      {
        page: 0,
        perPage: 25,
        projectStatus: "inSettlement",
      }
    );

  const { data: settlementListData } =
    ADMIN_SETTLEMENT_QUERY.useGetSettlementList({
      ...fetchSettlementListParams,
    });

  const { adminUserList } = ADMIN_USER_QUERY.useGetAdminUserList();

  return (
    <Layout title="정산관리">
      <SettlementTableFilter
        adminUserList={adminUserList}
        fetchSettlementListParams={fetchSettlementListParams}
        setFetchSettlementListParams={setFetchSettlementListParams}
      />

      <SettlementTable
        settlementListData={settlementListData}
        adminUserList={adminUserList}
        fetchSettlementListParams={fetchSettlementListParams}
        setFetchSettlementListParams={setFetchSettlementListParams}
      />
    </Layout>
  );
}

export default SettlementList;
