import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { ShipmentUserCompanyNameList } from "@sellernote/_shared/src/types/forwarding/adminBid";

import Modal from "../../../../../../components/Modal";

function CompanyNameUpdateModal({
  opensCompanyNameUpdateModal,
  onModalClose,
  userCompanyNameList,
  shipmentTeamId,
  onSelectChange,
  onUpdateButtonClick,
}: {
  opensCompanyNameUpdateModal: boolean;
  onModalClose: () => void;
  userCompanyNameList: ShipmentUserCompanyNameList[];
  shipmentTeamId: number;
  onSelectChange: (value: string | number) => void;
  onUpdateButtonClick: () => void;
}) {
  return (
    <Modal
      isOpened={opensCompanyNameUpdateModal}
      handleClose={onModalClose}
      modalBody={
        <Box display={"flex"} flexDirection={"column"} width={300} gap={1}>
          <Typography component={"span"} fontWeight={"bold"} sx={{ mb: 3 }}>
            회사명 변경
          </Typography>

          <FormControl variant="standard">
            <Select
              value={shipmentTeamId}
              /** MUI Select는 string을 리턴하고 있어 number로 변환 이때 예외 사항을 확인해서 에러 호출 */
              onChange={(e) => {
                onSelectChange(e.target.value);
              }}
            >
              {userCompanyNameList.map((userCompanyName) => {
                return (
                  <MenuItem key={userCompanyName.id} value={userCompanyName.id}>
                    {`회사명: ${userCompanyName.companyName || "-"} / 팀명: ${
                      userCompanyName.teamName || "-"
                    }`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            sx={{ mt: 3 }}
            size="small"
            onClick={onUpdateButtonClick}
          >
            수정
          </Button>
        </Box>
      }
    />
  );
}

export default CompanyNameUpdateModal;
