/** 로지스뷰에 입력 가능한 컨테이너 Size/Type 정보 */
const VALID_SIZE_TYPE_LIST = [
  "10GP",
  "12GP",
  "12RE",
  "20GP",
  "20VH",
  "20HI",
  "20RE",
  "20UT",
  "20PC",
  "20PF",
  "20TN",
  "20TD",
  "20TG",
  "20BK",
  "22GP",
  "22PF",
  "22PL",
  "22VH",
  "20RH",
  "22RE",
  "22HT",
  "22UT",
  "22PC",
  "22TN",
  "22TD",
  "22TG",
  "22BK",
  "24GP",
  "25GP",
  "22HR",
  "28UT",
  "28PC",
  "28TN",
  "29PL",
  "40GP",
  "40UT",
  "40PL",
  "40TN",
  "42GP",
  "42UT",
  "42PC",
  "42PF",
  "42TD",
  "42BU",
  "42VH",
  "42RE",
  "42HI",
  "43PL",
  "43PC",
  "42PL",
  "42TN",
  "42TG",
  "42BK",
  "45RE",
  "44RT",
  "45GP",
  "44GP",
  "40HC",
  "44HP",
  "40RE",
  "42HR",
  "45RT",
  "44RE",
  "45PC",
  "40HO",
  "45UT",
  "48HC",
  "48UT",
  "48PC",
  "48PF",
  "45HC",
  "L5GP",
  "L0GP",
  "45VH",
  "45HQ",
  "45TN",
  "L2GP",
  "L4GP",
  "M5GP",
  "PPGP",
];

export default VALID_SIZE_TYPE_LIST;
